import { GraphQLClient } from 'graphql-request';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(client: GraphQLClient, query: string, variables?: TVariables) {
  return async (): Promise<TData> => client.request<TData, TVariables>(query, variables);
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Assessment = {
  __typename?: 'Assessment';
  /** Unique UUID. Auto generated at the time of saving the Assessment */
  id: Scalars['ID'];
  /** Patient for this assessment */
  patient?: Maybe<Patient>;
  /** @deprecated use Patient field */
  patientId?: Maybe<Scalars['String']>;
  /** Unique Id for each Inference request, Used for troubleshooting and logging. Each time when the model prediction is requested. System generates uniqueId for future reference  */
  inferenceUniqueId?: Maybe<Scalars['String']>;
  /** Inference result. Possible values High / Low / NotAvailable. This is a overall result */
  inferenceResult?: Maybe<InferenceResultType>;
  /** First Visit Baby Model Inference result. Possible values High / Low / NotAvailable */
  firstVisitBabyInferenceResult?: Maybe<InferenceResultType>;
  /** First Visit Baby Model Inference Score for High or Low in percentage */
  firstVisitBabyInferenceScorePercent?: Maybe<Scalars['Float']>;
  /** First Visit Mother Model Inference result. Possible values High / Low / NotAvailable */
  firstVisitMotherInferenceResult?: Maybe<InferenceResultType>;
  /** First Visit Mother Model Inference Score for High or Low in percentage */
  firstVisitMotherInferenceScorePercent?: Maybe<Scalars['Float']>;
  /** First Visit Baby and Mother Model Inference result. Possible values High / Low / NotAvailable */
  firstVisitBabyMotherInferenceResult?: Maybe<InferenceResultType>;
  /** First Visit Baby and Mother Model Inference Score for High or Low in percentage */
  firstVisitBabyMotherInferenceScorePercent?: Maybe<Scalars['Float']>;
  /** Late Stage Baby Model Inference result. Possible values High / Low / NotAvailable */
  lateStageBabyInferenceResult?: Maybe<InferenceResultType>;
  /** Late Stage Baby Model Inference Score for High or Low in percentage */
  lateStageBabyInferenceScorePercent?: Maybe<Scalars['Float']>;
  /** Timestamp at the time of creation. Auto generated at the time of saving */
  createdAt: Scalars['DateTime'];
  /** User Id of the creator, Id comes from identity server */
  createdByUserId: Scalars['String'];
  /** Timestamp at the time of updatation. Auto generated at the time of saving */
  updatedAt: Scalars['DateTime'];
  /** User Id of the updator, Id comes from identity server */
  updatedByUserId: Scalars['String'];
  /** Flag represents whether the assessment is draft version */
  isFinalVersion: Scalars['Boolean'];
  /** Feature For the assessments */
  features?: Maybe<Array<Feature>>;
  /** Created by user details, object comes from identity server */
  createdBy?: Maybe<User>;
  /** Updated by user details, object comes from identity server */
  updatedBy?: Maybe<User>;
};

export type AssessmentInput = {
  patientId: Scalars['ID'];
  features: Array<FeatureInput>;
  isFinalVersion?: Maybe<Scalars['Boolean']>;
};

export type AssessmentSearchInput = {
  patientId: Scalars['ID'];
  createdBy?: Maybe<Scalars['String']>;
};

export type AssessmentsOrderByInput = {
  updatedAt?: Maybe<SortOrder>;
};


export type Feature = {
  __typename?: 'Feature';
  /** Assessment reference Id */
  assessmentId: Scalars['ID'];
  /** Future number, unique for each assessment */
  featureNumber: Scalars['Int'];
  value?: Maybe<Scalars['Float']>;
  /** Timestamp at the time of creation. Auto generated at the time of saving */
  createdAt: Scalars['DateTime'];
  /** User Id of the creator, Id comes from identity server */
  createdByUserId: Scalars['String'];
  /** User role of the creator, value comes from identity server.  */
  createdByUserRole: Scalars['String'];
  /** Timestamp at the time of creation. Auto generated at the time of saving */
  updatedAt: Scalars['DateTime'];
  /** User Id of the creator, Id comes from identity server */
  updatedByUserId: Scalars['String'];
  /** User role of the creator, value comes from identity server.  */
  updatedByUserRole: Scalars['String'];
};

export type FeatureInput = {
  featureNumber: Scalars['Int'];
  value: Scalars['Float'];
};

export enum InferenceResultType {
  Draft = 'Draft',
  High = 'High',
  Low = 'Low',
  NotAvailable = 'NotAvailable'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Register new patient in the system. */
  registerPatient?: Maybe<Patient>;
  /** Update patient details */
  updatePatient?: Maybe<Patient>;
  deletePatient?: Maybe<Scalars['Boolean']>;
  saveAssessment: Assessment;
  saveAssessmentForSurvey: Assessment;
  updateAssessment: Assessment;
};


export type MutationRegisterPatientArgs = {
  patientInput: PatientInput;
};


export type MutationUpdatePatientArgs = {
  patientInput: PatientInput;
  patientId: Scalars['String'];
};


export type MutationDeletePatientArgs = {
  patientId?: Maybe<Scalars['String']>;
};


export type MutationSaveAssessmentArgs = {
  assessmentInput: AssessmentInput;
};


export type MutationSaveAssessmentForSurveyArgs = {
  assessmentInput: AssessmentInput;
};


export type MutationUpdateAssessmentArgs = {
  assessmentInput: AssessmentInput;
  assessmentId: Scalars['String'];
};

export type Patient = {
  __typename?: 'Patient';
  /** Unique UUID. Auto generated at the time of registering the patient */
  id?: Maybe<Scalars['ID']>;
  /** Unique user name */
  username: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** Timestamp at the time of creation. Auto generated at the time of saving */
  createdTimestamp?: Maybe<Scalars['DateTime']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  /** National Registration Identity Card Id */
  nric?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  /** Flage to represent whether this patient record can be deleted. If this patient has assessments, the record cannot be deleted  */
  canDelete?: Maybe<Scalars['Boolean']>;
  /** List of assessments */
  assessments?: Maybe<Array<Maybe<Assessment>>>;
};


export type PatientAssessmentsArgs = {
  assessmentsOrderBy?: Maybe<AssessmentsOrderByInput>;
  assessmentsTake?: Maybe<Scalars['Int']>;
};

export type PatientInput = {
  /** Unique user name */
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  /** National Registration Identity Card Id */
  nric?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type PatientSearchInput = {
  /** Unique user name */
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** National Registration Identity Card Id */
  nric?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  /** Inference result. Possible values High / Low */
  inferenceResult?: Maybe<InferenceResultType>;
};

export type Patients = {
  __typename?: 'Patients';
  /** Index of the record. */
  start?: Maybe<Scalars['Int']>;
  /** Requested number of records per page  */
  perPage?: Maybe<Scalars['Int']>;
  /** Total number of records avalable for this filters  */
  total?: Maybe<Scalars['Int']>;
  /** List of patients  */
  results?: Maybe<Array<Patient>>;
  orderBy?: Maybe<PatientsOrderBy>;
};

export type PatientsOrderBy = {
  __typename?: 'PatientsOrderBy';
  username?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  birthdate?: Maybe<SortOrder>;
  nric?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  createdTimestamp?: Maybe<SortOrder>;
  inferenceResult?: Maybe<SortOrder>;
};

export type PatientsOrderByInput = {
  username?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  birthdate?: Maybe<SortOrder>;
  nric?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  createdTimestamp?: Maybe<SortOrder>;
  inferenceResult?: Maybe<SortOrder>;
};

/** Permission for the logged-in user */
export type Permission = {
  __typename?: 'Permission';
  /** Resource name. Example: 'patient-resource / assessment-resource' */
  rsname?: Maybe<Scalars['String']>;
  /** List of scopes for this permission. Example: 'view-scope' / 'update-scope' */
  scopes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Query = {
  __typename?: 'Query';
  getPermissions?: Maybe<Array<Maybe<Permission>>>;
  /** Query to get the users. Users can be cany role, for example: doctor | nurse or patient */
  getUsers?: Maybe<Array<Maybe<User>>>;
  me?: Maybe<User>;
  /** Query to get the single patient */
  getPatient: Patient;
  /** Query to get the list of patients */
  getPatients: Patients;
  getAssessments: Array<Assessment>;
  getAssessmentForSurvey?: Maybe<Assessment>;
  getAssessmentsForSurvey?: Maybe<Array<Maybe<Assessment>>>;
};


export type QueryGetUsersArgs = {
  userSearchInput: UserSearchInput;
};


export type QueryGetPatientArgs = {
  patientId: Scalars['String'];
};


export type QueryGetPatientsArgs = {
  patientSearchInput: PatientSearchInput;
  patientsOrderByInput?: Maybe<PatientsOrderByInput>;
  patientsTake?: Maybe<Scalars['Int']>;
  patientsSkip?: Maybe<Scalars['Int']>;
};


export type QueryGetAssessmentForSurveyArgs = {
  assessmentId?: Maybe<Scalars['String']>;
};


export type QueryGetAssessmentsForSurveyArgs = {
  assessmentSearchInput?: Maybe<AssessmentSearchInput>;
  assessmentsOrderBy?: Maybe<AssessmentsOrderByInput>;
  assessmentsTake?: Maybe<Scalars['Int']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type Subscription = {
  __typename?: 'Subscription';
  assessmentUpdated: Assessment;
};

export type User = {
  __typename?: 'User';
  /** Unique UUID. Auto generated at the time of saving the User */
  id: Scalars['ID'];
  /** Unique string to represent user */
  username: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type UserSearchInput = {
  /** Unique user name */
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type OnAssessmentUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnAssessmentUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { assessmentUpdated: (
    { __typename?: 'Assessment' }
    & Pick<Assessment, 'id' | 'patientId' | 'updatedByUserId'>
  ) }
);

export type GetPatientWithAssessmentsQueryVariables = Exact<{
  patientId: Scalars['String'];
}>;


export type GetPatientWithAssessmentsQuery = (
  { __typename?: 'Query' }
  & { getPatient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'firstName' | 'lastName' | 'email' | 'createdTimestamp' | 'birthdate' | 'nric' | 'phoneNumber'>
    & { assessments?: Maybe<Array<Maybe<(
      { __typename?: 'Assessment' }
      & Pick<Assessment, 'id' | 'inferenceResult' | 'firstVisitBabyInferenceResult' | 'firstVisitBabyInferenceScorePercent' | 'firstVisitMotherInferenceResult' | 'firstVisitMotherInferenceScorePercent' | 'firstVisitBabyMotherInferenceResult' | 'firstVisitBabyMotherInferenceScorePercent' | 'lateStageBabyInferenceResult' | 'lateStageBabyInferenceScorePercent' | 'inferenceUniqueId' | 'isFinalVersion' | 'updatedAt'>
      & { updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName'>
      )>, features?: Maybe<Array<(
        { __typename?: 'Feature' }
        & Pick<Feature, 'featureNumber' | 'value' | 'updatedByUserRole'>
      )>> }
    )>>> }
  ) }
);

export type SaveAssessmentMutationVariables = Exact<{
  assessmentInput: AssessmentInput;
}>;


export type SaveAssessmentMutation = (
  { __typename?: 'Mutation' }
  & { saveAssessment: (
    { __typename?: 'Assessment' }
    & Pick<Assessment, 'id'>
  ) }
);

export type UpdateAssessmentMutationVariables = Exact<{
  assessmentInput: AssessmentInput;
  assessmentId: Scalars['String'];
}>;


export type UpdateAssessmentMutation = (
  { __typename?: 'Mutation' }
  & { updateAssessment: (
    { __typename?: 'Assessment' }
    & Pick<Assessment, 'id'>
  ) }
);

export type DeletePatientMutationVariables = Exact<{
  patientId?: Maybe<Scalars['String']>;
}>;


export type DeletePatientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePatient'>
);

export type GetPatientQueryVariables = Exact<{
  patientId: Scalars['String'];
}>;


export type GetPatientQuery = (
  { __typename?: 'Query' }
  & { getPatient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'firstName' | 'lastName' | 'email' | 'createdTimestamp' | 'birthdate' | 'nric' | 'phoneNumber'>
  ) }
);

export type GetPatientsQueryVariables = Exact<{
  patientSearchInput: PatientSearchInput;
  patientsOrderByInput?: Maybe<PatientsOrderByInput>;
  patientsTake?: Maybe<Scalars['Int']>;
  patientsSkip?: Maybe<Scalars['Int']>;
}>;


export type GetPatientsQuery = (
  { __typename?: 'Query' }
  & { getPatients: (
    { __typename?: 'Patients' }
    & Pick<Patients, 'start' | 'perPage' | 'total'>
    & { orderBy?: Maybe<(
      { __typename?: 'PatientsOrderBy' }
      & Pick<PatientsOrderBy, 'firstName' | 'lastName' | 'email' | 'createdTimestamp' | 'birthdate' | 'nric' | 'phoneNumber' | 'inferenceResult'>
    )>, results?: Maybe<Array<(
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'firstName' | 'lastName' | 'email' | 'createdTimestamp' | 'birthdate' | 'nric' | 'phoneNumber' | 'canDelete'>
    )>> }
  ) }
);

export type GetPatientsWithAssessmentsQueryVariables = Exact<{
  patientSearchInput: PatientSearchInput;
  patientsOrderByInput?: Maybe<PatientsOrderByInput>;
  patientsTake?: Maybe<Scalars['Int']>;
  patientsSkip?: Maybe<Scalars['Int']>;
  assessmentsOrderBy?: Maybe<AssessmentsOrderByInput>;
  assessmentsTake?: Maybe<Scalars['Int']>;
}>;


export type GetPatientsWithAssessmentsQuery = (
  { __typename?: 'Query' }
  & { getPatients: (
    { __typename?: 'Patients' }
    & Pick<Patients, 'start' | 'perPage' | 'total'>
    & { orderBy?: Maybe<(
      { __typename?: 'PatientsOrderBy' }
      & Pick<PatientsOrderBy, 'firstName' | 'lastName' | 'email' | 'createdTimestamp' | 'birthdate' | 'nric' | 'phoneNumber' | 'inferenceResult'>
    )>, results?: Maybe<Array<(
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'firstName' | 'lastName' | 'email' | 'createdTimestamp' | 'birthdate' | 'nric' | 'phoneNumber' | 'canDelete'>
      & { assessments?: Maybe<Array<Maybe<(
        { __typename?: 'Assessment' }
        & Pick<Assessment, 'inferenceResult'>
      )>>> }
    )>> }
  ) }
);

export type GetUsersQueryVariables = Exact<{
  userSearchInput: UserSearchInput;
}>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { getUsers?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'firstName' | 'id'>
  )>>> }
);

export type RegisterPatientMutationVariables = Exact<{
  patientInput: PatientInput;
}>;


export type RegisterPatientMutation = (
  { __typename?: 'Mutation' }
  & { registerPatient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'firstName' | 'lastName' | 'email' | 'birthdate' | 'nric' | 'phoneNumber'>
  )> }
);

export type UpdatePatientMutationVariables = Exact<{
  patientInput: PatientInput;
  patientId: Scalars['String'];
}>;


export type UpdatePatientMutation = (
  { __typename?: 'Mutation' }
  & { updatePatient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'firstName' | 'lastName' | 'email' | 'birthdate' | 'nric' | 'phoneNumber'>
  )> }
);

export type GetPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPermissionsQuery = (
  { __typename?: 'Query' }
  & { getPermissions?: Maybe<Array<Maybe<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'rsname' | 'scopes'>
  )>>> }
);


export const OnAssessmentUpdatedDocument = `
    subscription OnAssessmentUpdated {
  assessmentUpdated {
    id
    patientId
    updatedByUserId
  }
}
    `;
export const GetPatientWithAssessmentsDocument = `
    query GetPatientWithAssessments($patientId: String!) {
  getPatient(patientId: $patientId) {
    id
    firstName
    lastName
    email
    createdTimestamp
    birthdate
    nric
    phoneNumber
    assessments {
      id
      inferenceResult
      firstVisitBabyInferenceResult
      firstVisitBabyInferenceScorePercent
      firstVisitMotherInferenceResult
      firstVisitMotherInferenceScorePercent
      firstVisitBabyMotherInferenceResult
      firstVisitBabyMotherInferenceScorePercent
      lateStageBabyInferenceResult
      lateStageBabyInferenceScorePercent
      inferenceUniqueId
      isFinalVersion
      updatedAt
      updatedBy {
        firstName
        lastName
      }
      features {
        featureNumber
        value
        updatedByUserRole
      }
    }
  }
}
    `;
export const useGetPatientWithAssessmentsQuery = <
      TData = GetPatientWithAssessmentsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetPatientWithAssessmentsQueryVariables, 
      options?: UseQueryOptions<GetPatientWithAssessmentsQuery, TError, TData>
    ) => 
    useQuery<GetPatientWithAssessmentsQuery, TError, TData>(
      ['GetPatientWithAssessments', variables],
      fetcher<GetPatientWithAssessmentsQuery, GetPatientWithAssessmentsQueryVariables>(client, GetPatientWithAssessmentsDocument, variables),
      options
    );
export const SaveAssessmentDocument = `
    mutation SaveAssessment($assessmentInput: AssessmentInput!) {
  saveAssessment(assessmentInput: $assessmentInput) {
    id
  }
}
    `;
export const useSaveAssessmentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<SaveAssessmentMutation, TError, SaveAssessmentMutationVariables, TContext>
    ) => 
    useMutation<SaveAssessmentMutation, TError, SaveAssessmentMutationVariables, TContext>(
      (variables?: SaveAssessmentMutationVariables) => fetcher<SaveAssessmentMutation, SaveAssessmentMutationVariables>(client, SaveAssessmentDocument, variables)(),
      options
    );
export const UpdateAssessmentDocument = `
    mutation UpdateAssessment($assessmentInput: AssessmentInput!, $assessmentId: String!) {
  updateAssessment(assessmentInput: $assessmentInput, assessmentId: $assessmentId) {
    id
  }
}
    `;
export const useUpdateAssessmentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateAssessmentMutation, TError, UpdateAssessmentMutationVariables, TContext>
    ) => 
    useMutation<UpdateAssessmentMutation, TError, UpdateAssessmentMutationVariables, TContext>(
      (variables?: UpdateAssessmentMutationVariables) => fetcher<UpdateAssessmentMutation, UpdateAssessmentMutationVariables>(client, UpdateAssessmentDocument, variables)(),
      options
    );
export const DeletePatientDocument = `
    mutation DeletePatient($patientId: String) {
  deletePatient(patientId: $patientId)
}
    `;
export const useDeletePatientMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<DeletePatientMutation, TError, DeletePatientMutationVariables, TContext>
    ) => 
    useMutation<DeletePatientMutation, TError, DeletePatientMutationVariables, TContext>(
      (variables?: DeletePatientMutationVariables) => fetcher<DeletePatientMutation, DeletePatientMutationVariables>(client, DeletePatientDocument, variables)(),
      options
    );
export const GetPatientDocument = `
    query GetPatient($patientId: String!) {
  getPatient(patientId: $patientId) {
    id
    firstName
    lastName
    email
    createdTimestamp
    birthdate
    nric
    phoneNumber
  }
}
    `;
export const useGetPatientQuery = <
      TData = GetPatientQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetPatientQueryVariables, 
      options?: UseQueryOptions<GetPatientQuery, TError, TData>
    ) => 
    useQuery<GetPatientQuery, TError, TData>(
      ['GetPatient', variables],
      fetcher<GetPatientQuery, GetPatientQueryVariables>(client, GetPatientDocument, variables),
      options
    );
export const GetPatientsDocument = `
    query GetPatients($patientSearchInput: PatientSearchInput!, $patientsOrderByInput: PatientsOrderByInput, $patientsTake: Int, $patientsSkip: Int) {
  getPatients(
    patientSearchInput: $patientSearchInput
    patientsOrderByInput: $patientsOrderByInput
    patientsTake: $patientsTake
    patientsSkip: $patientsSkip
  ) {
    start
    perPage
    total
    orderBy {
      firstName
      lastName
      email
      createdTimestamp
      birthdate
      nric
      phoneNumber
      inferenceResult
    }
    results {
      id
      firstName
      lastName
      email
      createdTimestamp
      birthdate
      nric
      phoneNumber
      canDelete
    }
  }
}
    `;
export const useGetPatientsQuery = <
      TData = GetPatientsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetPatientsQueryVariables, 
      options?: UseQueryOptions<GetPatientsQuery, TError, TData>
    ) => 
    useQuery<GetPatientsQuery, TError, TData>(
      ['GetPatients', variables],
      fetcher<GetPatientsQuery, GetPatientsQueryVariables>(client, GetPatientsDocument, variables),
      options
    );
export const GetPatientsWithAssessmentsDocument = `
    query GetPatientsWithAssessments($patientSearchInput: PatientSearchInput!, $patientsOrderByInput: PatientsOrderByInput, $patientsTake: Int, $patientsSkip: Int, $assessmentsOrderBy: AssessmentsOrderByInput, $assessmentsTake: Int) {
  getPatients(
    patientSearchInput: $patientSearchInput
    patientsOrderByInput: $patientsOrderByInput
    patientsTake: $patientsTake
    patientsSkip: $patientsSkip
  ) {
    start
    perPage
    total
    orderBy {
      firstName
      lastName
      email
      createdTimestamp
      birthdate
      nric
      phoneNumber
      inferenceResult
    }
    results {
      id
      firstName
      lastName
      email
      createdTimestamp
      birthdate
      nric
      phoneNumber
      canDelete
      assessments(
        assessmentsOrderBy: $assessmentsOrderBy
        assessmentsTake: $assessmentsTake
      ) {
        inferenceResult
      }
    }
  }
}
    `;
export const useGetPatientsWithAssessmentsQuery = <
      TData = GetPatientsWithAssessmentsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetPatientsWithAssessmentsQueryVariables, 
      options?: UseQueryOptions<GetPatientsWithAssessmentsQuery, TError, TData>
    ) => 
    useQuery<GetPatientsWithAssessmentsQuery, TError, TData>(
      ['GetPatientsWithAssessments', variables],
      fetcher<GetPatientsWithAssessmentsQuery, GetPatientsWithAssessmentsQueryVariables>(client, GetPatientsWithAssessmentsDocument, variables),
      options
    );
export const GetUsersDocument = `
    query GetUsers($userSearchInput: UserSearchInput!) {
  getUsers(userSearchInput: $userSearchInput) {
    email
    firstName
    id
  }
}
    `;
export const useGetUsersQuery = <
      TData = GetUsersQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: GetUsersQueryVariables, 
      options?: UseQueryOptions<GetUsersQuery, TError, TData>
    ) => 
    useQuery<GetUsersQuery, TError, TData>(
      ['GetUsers', variables],
      fetcher<GetUsersQuery, GetUsersQueryVariables>(client, GetUsersDocument, variables),
      options
    );
export const RegisterPatientDocument = `
    mutation RegisterPatient($patientInput: PatientInput!) {
  registerPatient(patientInput: $patientInput) {
    firstName
    lastName
    email
    birthdate
    nric
    phoneNumber
  }
}
    `;
export const useRegisterPatientMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<RegisterPatientMutation, TError, RegisterPatientMutationVariables, TContext>
    ) => 
    useMutation<RegisterPatientMutation, TError, RegisterPatientMutationVariables, TContext>(
      (variables?: RegisterPatientMutationVariables) => fetcher<RegisterPatientMutation, RegisterPatientMutationVariables>(client, RegisterPatientDocument, variables)(),
      options
    );
export const UpdatePatientDocument = `
    mutation UpdatePatient($patientInput: PatientInput!, $patientId: String!) {
  updatePatient(patientInput: $patientInput, patientId: $patientId) {
    id
    firstName
    lastName
    email
    birthdate
    nric
    phoneNumber
  }
}
    `;
export const useUpdatePatientMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdatePatientMutation, TError, UpdatePatientMutationVariables, TContext>
    ) => 
    useMutation<UpdatePatientMutation, TError, UpdatePatientMutationVariables, TContext>(
      (variables?: UpdatePatientMutationVariables) => fetcher<UpdatePatientMutation, UpdatePatientMutationVariables>(client, UpdatePatientDocument, variables)(),
      options
    );
export const GetPermissionsDocument = `
    query GetPermissions {
  getPermissions {
    rsname
    scopes
  }
}
    `;
export const useGetPermissionsQuery = <
      TData = GetPermissionsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: GetPermissionsQueryVariables, 
      options?: UseQueryOptions<GetPermissionsQuery, TError, TData>
    ) => 
    useQuery<GetPermissionsQuery, TError, TData>(
      ['GetPermissions', variables],
      fetcher<GetPermissionsQuery, GetPermissionsQueryVariables>(client, GetPermissionsDocument, variables),
      options
    );