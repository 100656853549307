import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { Button } from '@mui/material';

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" size='6em' {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        Reload
      </Box>
    </Box>

  );
}

export default function CircularStatic(
    props: { reload: any }) {
  const [progress, setProgress] = React.useState(99);
  let timer:  any;

    const onReload = () => {
        clearInterval(timer);
        setTimeout(() => {
            props.reload();
        }); 
    }

  React.useEffect(() => {
    timer = setInterval(() => {
        setProgress((prevProgress) => {
            if(prevProgress <= 2){
                onReload();
                
            }
            return prevProgress - 3;

        });
    }, 120);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
        <Button variant="text" sx={{color: '#aa7dff', fontWeight: 'bold'}} onClick={onReload}>
            <CircularProgressWithLabel value={progress} />
        </Button>
    );
}

