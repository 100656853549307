import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

function Users() {

  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>
      
      <Container maxWidth="lg">
        <Outlet></Outlet>
      </Container>
    </>
  );
}

export default Users;
