import { FC, useState, createContext } from 'react';






type SidebarContext = { sidebarToggle: any; toggleSidebar: () => void };

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

type SidebarProviderProps = {
  children: React.ReactNode;
};





export const SidebarProvider: FC<SidebarProviderProps> = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };



  


  return (

        <SidebarContext.Provider value={{ sidebarToggle, toggleSidebar }}>
          {children}
          
        </SidebarContext.Provider>


  );
};
