
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, IconButton, Paper, TableContainer, Tooltip, Typography} from '@mui/material';
import { useParams } from 'react-router-dom';
import AssessmentsTable from './assessments-table';
import PatientInfoTable from './patient-info-table';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { Link } from "react-router-dom"
import { useContext, useEffect, useRef, useState } from 'react';
import { AssessmentResponse, AssessmentGetRequest, AssessmentStatus, AssessmentPostRequest, ButtonMode, AssessmentMode } from './patient-assessment.model';
import ScrollToTop from './scroll-to-top';
import { ApiURL, MethodType, RequestConfig } from 'src/hooks/api-params';
import { PatientModel } from 'src/Patients/patient.model';
import { useApiQuery } from 'src/hooks/useApiQuery';
import { ApiListResponse } from 'src/models/api-response';
import { useApiMutation } from 'src/hooks/useApiMutation';
import { useToasts } from 'react-toast-notifications';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import { AssessmentTableRefModel } from './assessment-table-ref-model';
import { useGraphqlRequestClient } from 'src/clients/graphqlRequestClient';
import { Assessment, AssessmentInput, GetPatientQuery, GetPatientsQuery, GetPatientsQueryVariables, GetPatientWithAssessmentsQuery, OnAssessmentUpdatedDocument, OnAssessmentUpdatedSubscription, Patient, useGetPatientQuery, useGetPatientsQuery, 
    useGetPatientWithAssessmentsQuery, 
    useSaveAssessmentMutation,
    useUpdateAssessmentMutation} from 'src/generated/graphql';
import { PermissionUtil } from 'src/permissions/PermissionUtil';
import { Resource, Scope } from 'src/permissions/permissionsConstants';
import { PermissionContext } from 'src/permissions/PermissionContext';
import { gql, useSubscription } from '@apollo/client';
import { useGetUser } from 'src/hooks/useGetUser';
import CircularStatic from 'src/shared/CircularProgress/circularProgressWithLabel';

// import { useSubscription, gql } from '@apollo/client';

function PatientAssessments() {

    const [patientId, setPatientId] = useState<string>('');
    const [ patient, setPatient] = useState<Patient>();
    let [isReadonly, setReadonly] = useState<boolean>(true);
    let [buttonMode, setButtonMode] = useState<ButtonMode>(ButtonMode.New);
    const [assessments, setAssessments] = useState<Assessment[]>();
    const [assessmentId, setAssessmentId] = useState<string>('');
    const [showHistory, setShowHistory] = useState<boolean>(true);

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const userInstance = useGetUser();
    const urlParam = useParams();
    const { graphqlRequestClientInstance, clientInitialized } = useGraphqlRequestClient();
    const { addToast } = useToasts();

    const [progress, setProgress] = useState(10);
    let { refetch: fetchPatient } = useGetPatientWithAssessmentsQuery<GetPatientWithAssessmentsQuery>(graphqlRequestClientInstance.current, 
        {patientId: patientId}, {enabled: false});

    let { mutateAsync: saveAssessment, isLoading: isAssessmentLoadingAtSave } = useSaveAssessmentMutation<Assessment>(graphqlRequestClientInstance.current);
    let { mutateAsync: updateAssessment, isLoading: isAssessmentLoadingAtUpdate } = useUpdateAssessmentMutation<Assessment>(graphqlRequestClientInstance.current);



    const assessmentTableRef = useRef<AssessmentTableRefModel>(null);
    const tableContainerRef = useRef(null);
    const permissions = useContext(PermissionContext);

    
    useEffect(() => {
        if (urlParam.id){
          setPatientId(urlParam.id);
        }
      }, [urlParam.id]);
    
      useEffect(() => {
        if(clientInitialized && patientId){
            loadPatientandAssessments();
        }
      }, [patientId, clientInitialized, permissions]);
      

    const loadPatientandAssessments = () => {
        if(PermissionUtil.isAllowed(permissions, Resource.Assessment, Scope.View)){
            fetchPatient().then((resp) => {
                const patient = resp.data.getPatient as Patient;
                setPatient(patient);
                setAssessments([...patient.assessments]);
                setAssessmentId('');
              });
        }

    }

    const handleSubmit = async () => {
        const assessmentInput: AssessmentInput = assessmentTableRef.current?.getData();
        assessmentInput.isFinalVersion = false;
        await saveAssessment({assessmentInput}).then((assessment) => {
            let message = "Inference result submited and generated successfully";
            addToast(message, {
                appearance: 'success',
                autoDismiss: true,
            });
            loadPatientandAssessments();
        }).catch((ex) => {
            console.log("Error while saving draft", ex);
            let message = "Unable to save as draft";
            addToast(message, {
                appearance: 'error',
                autoDismiss: true,
            });
        });

    }

    const handleSaveandSubmit = async () => {
        //Validate required fields
        const isValid: boolean = assessmentTableRef.current?.validate();
        if(!isValid){
            assessmentTableRef.current?.scrollToErrorText();
            return;
        }
        const assessmentInput: AssessmentInput = assessmentTableRef.current?.getData();
        assessmentInput.isFinalVersion = true;
        await saveAssessment({assessmentInput}).then((assessment) => {
            let message = "Inference result submited successfully";
            addToast(message, {
                appearance: 'success',
                autoDismiss: true,
            });
            loadPatientandAssessments();
        }).catch((ex) => {
            console.log("Error while Save and infer", ex);
            let message = "Unable to submit and infer";
            addToast(message, {
                appearance: 'error',
                autoDismiss: true,
            });
        });

    
    }

    const handleUpdate = async () => {
        if(assessmentId){
            const assessmentInput: AssessmentInput = assessmentTableRef.current?.getData();
            assessmentInput.isFinalVersion = false;
            await updateAssessment({assessmentInput, assessmentId}).then((assessment) => {
                let message = "Inference result updated successfully";
                addToast(message, {
                    appearance: 'success',
                    autoDismiss: true,
                });
                loadPatientandAssessments();
            }).catch((ex) => {
                console.log("Error while updating draft", ex);
                let message = "Unable to save draft";
                addToast(message, {
                    appearance: 'error',
                    autoDismiss: true,
                });
            });

        }
    }



    const handleUpdateandSubmit = async () => {
        if(assessmentId){
            //Validate required fields
            const isValid: boolean = assessmentTableRef.current?.validate();
            if(!isValid){
                assessmentTableRef.current?.scrollToErrorText();
                return;
            }
            const assessmentInput: AssessmentInput = assessmentTableRef.current?.getData();
            assessmentInput.isFinalVersion = true;
            const assessment = await updateAssessment({assessmentInput, assessmentId}).then((assessment) => {
                let message = "Inference result updated and generated successfully";
                addToast(message, {
                    appearance: 'success',
                    autoDismiss: true,
                });
                loadPatientandAssessments();
            }).catch((ex) => {
                console.log("Error while updated and infer", ex);
                let message = "Unable to update and infer";
                addToast(message, {
                    appearance: 'error',
                    autoDismiss: true,
                });
            });;

        }
    }
    


    const handleShowHistoryAssessment = () => {
        setShowHistory(!showHistory);
        assessmentTableRef.current?.showHistoryAssessments();
    }

    const handleModeChange = (assessmentMode: AssessmentMode) => {
        if(assessmentMode === AssessmentMode.Edit || assessmentMode === AssessmentMode.Readonly){
            setButtonMode(ButtonMode.Update);
        }else {
            setButtonMode(ButtonMode.New);
        }
        if(assessmentMode === AssessmentMode.Readonly){
            setReadonly(true);
        }else{
            setReadonly(false);
        }
    }

    const onReload = () => {
        setOpenDialog(false);
        loadPatientandAssessments();
    }


    const handleDialogClose = () => {
        setOpenDialog(false);
    }


    const setAssessmentIdForUpdate = (assessmentId: string) => {
        setAssessmentId(assessmentId);
    }

    const ASSESSMENT_UPDATED_SUBSCRIPTION = gql`${OnAssessmentUpdatedDocument}`;
  
    let { data: onAssessmentUpdatedSubscription, loading } = useSubscription<OnAssessmentUpdatedSubscription>(
        ASSESSMENT_UPDATED_SUBSCRIPTION
    );



    useEffect(() => {
        if(onAssessmentUpdatedSubscription){
            if(onAssessmentUpdatedSubscription.assessmentUpdated.patientId === patientId && onAssessmentUpdatedSubscription.assessmentUpdated.updatedByUserId !== userInstance.current.id){
                setOpenDialog(true);
            }
        }
    }, [onAssessmentUpdatedSubscription]);

  return (
    <>
            <Box display="flex" justifyContent='space-between' sx={{pt: 1}}>
                <PatientInfoTable patient= {patient} ></PatientInfoTable>
            </Box>

            <Box display="flex" justifyContent='flex-end' sx={{py: 1}} >
                <Box alignSelf='flex-end' >
                {
                    assessments?.length > 0 &&
                    <Button 
                        type='submit' 
                        value='History'
                        sx={{ mx:3, lineHeight: 2.5 }}
                        variant="contained"
                        onClick={handleShowHistoryAssessment}
                        disabled={isAssessmentLoadingAtSave || isReadonly}
                        {...(!isReadonly ? {color: 'primary'} : {})}
                        startIcon={<HistoryRoundedIcon fontSize="small" />}>
                        {showHistory?  "Hide History" : "Show History"}
                    </Button>
                }
                {
                    PermissionUtil.isAllowed(permissions, Resource.Assessment, Scope.Save) && buttonMode === ButtonMode.New &&
                    <>
                        <Button 
                            type='submit' 
                            value='Submit'
                            sx={{ mx:3, lineHeight: 2.5 }}
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={isAssessmentLoadingAtSave || isReadonly}
                            {...(!isReadonly ? {color: 'primary'} : {})}
                            
                            startIcon={<AddTwoToneIcon fontSize="small" />}>
                            Save draft
                        </Button>
                        <Button
                            type='submit' 
                            value='Submit'
                            sx={{  mx:3, lineHeight: 2.5 }}
                            variant="contained"
                            onClick={handleSaveandSubmit}
                            disabled={isAssessmentLoadingAtSave || isReadonly}
                            {...(!isReadonly ? {color: 'primary'} : {})}
                            startIcon={<AddTwoToneIcon fontSize="small" />}>
                            Submit & Infer
                        </Button>                    
                    </>
                    
                }

                {
                    PermissionUtil.isAllowed(permissions, Resource.Assessment, Scope.Update) && buttonMode === ButtonMode.Update &&
                    <>
                        <Button
                            type='submit' 
                            value='Submit'
                            sx={{ mx:3, lineHeight: 2.5 }}
                            variant="contained"
                            onClick={handleUpdate}
                            disabled={isAssessmentLoadingAtSave || isReadonly}
                            {...(!isReadonly ? {color: 'primary'} : {})}
                            startIcon={<AddTwoToneIcon fontSize="small" />}>
                                Save draft
                        </Button>
                        <Button
                            type='submit' 
                            value='Submit'
                            sx={{  mx:3, lineHeight: 2.5 }}
                            variant="contained"
                            onClick={handleUpdateandSubmit}
                            disabled={isAssessmentLoadingAtSave || isReadonly}
                            {...(!isReadonly ? {color: 'primary'} : {})}
                            startIcon={<AddTwoToneIcon fontSize="small" />}>
                                Update & Infer
                        </Button>                    
                    </>
                    
                }
                
                </Box>

            </Box>

             

            <Paper elevation={2}>
                {
                    assessments?.length > 0 &&
                    <Box  display='none' justifyContent='flex-end'
                        sx={{height:'5px'}} 
                        >
                        <Fab sx={{postion:'relative', left:'-3em', top: '3em'}}
                            color="primary" aria-label="add" size='small'
                            onClick={handleShowHistoryAssessment}
                            disabled={isAssessmentLoadingAtSave || isReadonly}
                            {...(!isReadonly ? {color: 'primary'} : {})}>
                            <HistoryRoundedIcon fontSize="small" />
                        </Fab>
                    </Box>
                }
                <Box>

                <TableContainer  className="table-container"
                    ref={tableContainerRef}>
                    <AssessmentsTable 
                        ref={assessmentTableRef}
                        patient= {patient}
                        assessments= {assessments}
                        onModeChange= {handleModeChange}
                        setAssessmentIdForUpdate= {setAssessmentIdForUpdate}
                        tableContainerRef= {tableContainerRef}
                        ></AssessmentsTable>
                </TableContainer>
                </Box>

            </Paper>
          {/* <ScrollToTop /> */}

          <Dialog open={openDialog} onClose={handleDialogClose}>
            <DialogTitle></DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Assessment for this patient has been updated in the server. Reload to get the updated data.
                </DialogContentText>
                <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                    <CircularStatic  reload={onReload}/>
                    <Button sx={{fontSize: '1em', color: '#aa7dff'}} onClick={handleDialogClose}>Cancel</Button>
                </Box>
                
            </DialogContent>
            <DialogActions>
            
            </DialogActions>
        </Dialog>

    </>
  );
}

export default PatientAssessments;
