
import { Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useContext } from 'react';
import { Role } from 'src/enums/user-enums';
import { Patient, InferenceResultType } from 'src/generated/graphql';
import { useGetUser } from 'src/hooks/useGetUser';
import { PermissionContext } from 'src/permissions/PermissionContext';
import { Resource, Scope } from 'src/permissions/permissionsConstants';
import { PermissionUtil } from 'src/permissions/PermissionUtil';

import './assessments.css'
import { ClinicalOutcome } from './patient-assessment.model';

function PatientInfoTable(props: { patient: Patient } ) {

  const permissions = useContext(PermissionContext);
  return (
    <>
        <Paper elevation={2} >
          <TableContainer >
            <Table style={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{py:'.5em'}}><Typography fontSize='0.9em' fontWeight="bold">MRN</Typography></TableCell>
                  <TableCell sx={{py:'.5em'}}><Typography fontSize='0.9em' fontWeight="bold">First Name</Typography></TableCell>
                  <TableCell sx={{py:'.5em'}}><Typography fontSize='0.9em' fontWeight="bold">Last Name</Typography></TableCell>
                  <TableCell sx={{py:'.5em'}}><Typography fontSize='0.9em' fontWeight="bold">Phone Number</Typography></TableCell>
                  {
                    PermissionUtil.isAllowed(permissions, Resource.Assessment, Scope.View) &&
                    <TableCell sx={{py:'.5em'}}><Typography fontSize='0.9em' fontWeight="bold">AI Inference Result</Typography></TableCell>
                  }
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell sx={{py:'.5em'}}>
                    <Typography fontSize='0.9em' color="text.primary" gutterBottom noWrap>
                      {props?.patient?.nric}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{py:'.5em'}}>
                    <Typography fontSize='0.9em' color="text.primary" gutterBottom noWrap>
                      {props?.patient?.firstName}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{py:'.5em'}}>
                    <Typography fontSize='0.9em' color="text.primary" gutterBottom noWrap>
                      {props?.patient?.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{py:'.5em'}}>
                    <Typography fontSize='0.9em' color="text.primary" gutterBottom noWrap>
                      {props?.patient?.phoneNumber}
                    </Typography>
                  </TableCell>
                    {
                      (PermissionUtil.isAllowed(permissions, Resource.Assessment, Scope.View) 
                        && (!props?.patient?.assessments || props?.patient?.assessments.length) === 0) &&
                      <TableCell></TableCell>
                    }
                    {
                      (PermissionUtil.isAllowed(permissions, Resource.Assessment, Scope.View) && 
                        (props?.patient?.assessments && props?.patient?.assessments.length > 0 
                            && props?.patient?.assessments[props?.patient?.assessments.length -1].inferenceResult === InferenceResultType.High)) &&
                        <TableCell sx={{py:'.5em'}}>
                          <Typography fontSize='0.9em' fontWeight="bold" color="error" gutterBottom noWrap>
                          HIGH
                        </Typography>
                      </TableCell>
                    }
                    {
                      (PermissionUtil.isAllowed(permissions, Resource.Assessment, Scope.View) && 
                        (props?.patient?.assessments && props?.patient?.assessments.length > 0 
                            && props?.patient?.assessments[props?.patient?.assessments.length -1].inferenceResult === InferenceResultType.Low)) &&
                        <TableCell sx={{py:'.5em'}}>
                          <Typography fontSize='0.9em' fontWeight="bold" color="#9CB96E" gutterBottom noWrap>
                            LOW
                          </Typography>
                        </TableCell>
                    }
                    {
                      (PermissionUtil.isAllowed(permissions, Resource.Assessment, Scope.View) && 
                        (props?.patient?.assessments && props?.patient?.assessments.length > 0 
                            && props?.patient?.assessments[props?.patient?.assessments.length -1].inferenceResult === InferenceResultType.NotAvailable)) &&
                        <TableCell sx={{py:'.5em'}}>
                          <Typography fontSize='0.9em' fontWeight="bold" color="#FFA500" gutterBottom noWrap>
                            N/A
                          </Typography>
                        </TableCell>
                    }      
                    {
                      (PermissionUtil.isAllowed(permissions, Resource.Assessment, Scope.View) && 
                        (props?.patient?.assessments && props?.patient?.assessments.length > 0 
                            && props?.patient?.assessments[props?.patient?.assessments.length -1].inferenceResult === InferenceResultType.Draft)) &&
                        <TableCell sx={{py:'.5em'}}>
                          <Typography fontSize='0.9em' fontWeight="bold" color="#FFA500" gutterBottom noWrap>
                            Draft
                          </Typography>
                        </TableCell>
                    }                                  
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
    </>
  );
}

export default PatientInfoTable;
