export enum Resource {
    Assessment = 'assessment-resource',
    Patient = 'patient-resource'
} 

export enum Scope {
    Register = 'register-scope',
    Save = 'save-scope',
    View = 'view-scope',
    Update = 'update-scope',
    Delete = 'delete-scope',

}