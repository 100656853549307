import { Role } from "src/enums/user-enums";
import { InferenceResultType } from "src/generated/graphql";


export interface AssessmentResponse {
    id?: number;
    user_id?: number;
    updated_at?: number;
    created_at?: number;
    creator_id?: number;
    remarks?: string;
    status?: AssessmentStatus;
    classificationscore?: number;
    autogenerated?: number;
    features?: FeatureResponse[]
}


export interface AssessmentGetRequest {
    user__id: number;
    ordering: string;
    page_size: number;
}

export interface AssessmentPostRequest {
    patientId: string;
    assessment_status?: AssessmentStatus;
    features: FeatureResponse[]
}

export interface FeatureResponse {
    number: number;
    value: any;
    role: Role;
}


export interface AssessmentHeader{
    assessmentId?: string;
    assessmentTimestamp?: number;
    inferenceResult?: InferenceResultType;
    firstVisitBabyInferenceResult?: InferenceResultType;
    firstVisitBabyInferenceScorePercent?: number;
    firstVisitMotherInferenceResult?: InferenceResultType;
    firstVisitMotherInferenceScorePercent?: number;
    firstVisitBabyMotherInferenceResult?: InferenceResultType;
    firstVisitBabyMotherInferenceScorePercent?: number;
    lateStageBabyInferenceResult?: InferenceResultType;
    lateStageBabyInferenceScorePercent?: number;
    inferenceUniqueId?: string;
    mode?: AssessmentHeaderMode;
    mainText?: string;
    subText?: string;
    hide?: boolean;
    updatedByFullName?: string;
    displayInfo?: boolean;
    displayInferenceDetails?: boolean;
    displayInferenceScore?: boolean;
}

export interface AssessmentFeature{
    displayName?: string;
    featureId?: number;
    featureValues?: AssessmentFeatureValue[];
    uniquerId?: string;
    visitType: VisitType;
    mandatory: boolean;
}

export interface AssessmentFeatureValue{
    assessmentId?: string;
    uniqueId?: string;
    //Todo: add a datatype to value
    value?: number;
    inputByUserType?: Role;
    config?: AssessmentFeatureConfig;
    highlight?: boolean;
    mode?: FeatureDisplayMode;
    hide?: boolean;
    status: boolean;
    isDirty: boolean;
}


export interface AssessmentFeatureConfig{
    featureId: number;
    displayName: string;
    visitType: VisitType;
    options?: {value: number, label: string}[];
    type: FeatureInputType,
    inputProps?: any,
    InputProps?: any,
    disable: boolean,
    mandatory: boolean
}

export enum FeatureDisplayMode{
    "Readonly" = 0,
    "Edit" = 1,
    "New" = 2
    
}
export enum AssessmentHeaderMode{
    "None" = 0,
    "ShowEdit" = 1,
    "ShowClose" = 2
    
}
export enum AssessmentStatus{
    "New" = -1,
    "Delete" = 0,
    "Ongoing" = 1,
    "Closed" = 2,
    "SavePredictClose" = 3
}


export enum FeatureInputType{
    "MultipleChoice" = 0,
    "Number" = 1
}

export enum ClinicalOutcome{
    "LowRisk" = 0,
    "HighRisk" = 1,
    "None" = 2
}

// export enum AssessmentResult{
//     "Low Risk" = 0,
//     "High Risk" = 1
// }

export enum VisitType{
    ClinicalInformationAtAdmission,
    PatientInformationAtAdmission,
    ClinicalInformationAtEachVisit,
    ClinicalInformationAtDelivery
    
}


export enum ButtonMode{
    Update,
    New
}

export enum AssessmentMode{
    Readonly,
    Edit,
    New
    
}
