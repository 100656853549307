import { Box, Link, Typography } from "@mui/material";

function Logo() {
  return (
    <Box display='flex' justifyContent='center' alignItems='center' width='100%'>
      <Typography variant="h1" component="div">
        <Link href="/overview" underline="none" sx={{color: '#223354'}}>
          Cura
        </Link>
      </Typography>
      <Typography variant='h3'>&trade;</Typography>
    </Box>
  );
}

export default Logo;
