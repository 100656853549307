import { AxiosRequestHeaders, Method } from "axios";

export enum MethodType{
    get = 'get',
    post = 'post',
    put = 'put',
    delete = 'delete'
}


export const BaseUrl = (window as any)._env_.REACT_APP_BACKEND_API;
export const BaseWSUrl = (window as any)._env_.REACT_APP_WS_API;
// Recommed to add a training slash
export enum ApiURL{
    patients = '/users/',
    assessments = '/assessments/',
    submissions = '/submissions/',
    importusers = '/importusers/'
    
}

export interface RequestConfig<TRequestData>{
    queryKey: string;
    method: MethodType;
    url: ApiURL | string;
    
    headers?: AxiosRequestHeaders;
    params?: TRequestData;
}