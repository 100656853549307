import { ReactNode } from 'react';

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
export interface MenuItem {
  link?: string;
  icon?: ReactNode | OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string; };
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  
  {
    heading: '',
    items: [
      {
        name: 'Patients',
        link: '/patients',
        icon: PersonOutlineIcon
      }
    ]
  }
];

export default menuItems;
