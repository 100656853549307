import { useRef, useState } from 'react';

import { NavLink } from 'react-router-dom';

import {
  Box,
  Button,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';

import { useKeycloak } from '@react-keycloak/web'
import jwt_decode from "jwt-decode";
import { KeycloakJwtPayload } from './token-payload';
import './index.css';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(2)};
        color: #000000;
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        color: ${theme.colors.alpha.black[100]}
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.colors.alpha.black[100]}
`
);

function HeaderUserbox() {
  const { keycloak } = useKeycloak();
  const signOut = (): void => {
    keycloak.logout();
  };


  const kcToken = keycloak?.token ?? '';
  var token : string = kcToken;
  let decoded: KeycloakJwtPayload;
  let title: string;
  let initials: string;

  if(token){
    decoded = jwt_decode<KeycloakJwtPayload>(token);
    title = decoded?.resource_access['antenatal-backend']?.roles.pop();
    initials = decoded?.given_name?.charAt(0)+""+decoded?.family_name?.charAt(0);
  }


  const user =
  {
    name: decoded?.name,
    jobtitle: title
  };

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };


  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <div className='profile-image'>{initials}</div>
        <Hidden mdDown>
          <UserBoxText sx={{ ml: 1 }}>
            <UserBoxLabel variant="body1" className='user-name'>{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2" className='user-title'>
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 3 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex" alignItems='center'>
          <div className='profile-image'>{initials}</div>
          <UserBoxText sx={{ ml: 1 }}>
            <UserBoxLabel variant="body1" className='user-name'>{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2" className='user-title'>
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button to="/overview" component={NavLink} onClick={signOut}>
          <LockOpenTwoToneIcon sx={{ mr: 3, color: '#E37081' }} />
            <ListItemText primary="Sign out" />
          </ListItem>
        </List>
      </Popover> 
    </>
  );
}

export default HeaderUserbox;
