
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { Box } from '@mui/material';

export default function MediaCard() {
  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
      <CardMedia
        component="img"
        height="140"
        image="/static/images/overview/ImportUsers_Image.svg"
        alt="error"
      />
      <CardContent sx = {{margin:'100px'}}>
        {/* <br />
        <br />
        <br /> */}
        <Box component="img" sx={{ justifyContent: 'space-between', align: 'center', bgcolor: 'background.paper', width: '150px'}} src="/static/images/overview/Import_Icon.svg"/>
        <Typography gutterBottom variant="h2" component="div" align='center'>
          Drag and drop your file here
        </Typography>
        <Typography variant="subtitle2" color="text.secondary" align='center' sx={{fontSize:"0.9rem"}}>
          or click to select files
        </Typography>
        {/* <br />
        <br />
        <br /> */}
      </CardContent>
    </Card>
  );
}
