import { useEffect, useRef } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import jwt_decode from "jwt-decode";
import { KeycloakJwtPayload } from 'src/layouts/SidebarLayout/Header/Userbox/token-payload';
import { Role } from 'src/enums/user-enums';
import { User } from 'src/models/user';

export const useGetUser = () => {
    const { keycloak } = useKeycloak();

    const kcToken = keycloak?.token ?? '';
    var token : string = kcToken;
    let decoded: KeycloakJwtPayload = null;
    if(token){
        decoded = jwt_decode<KeycloakJwtPayload>(token);
    }

    const userInstance = useRef<User>();

    useEffect(() => {
        let role : Role;
        let id: string;
        const clientId = (window as any)._env_.REACT_APP_IDENTITY_API_CLIENT_ID_API;
        if(decoded?.resource_access[clientId]?.roles){
            if(decoded?.resource_access[clientId]?.roles?.includes(Role.Patient)){
                role = Role.Patient;
            }else if((decoded?.resource_access[clientId]?.roles?.includes(Role.Nurse))){
                role = Role.Nurse;
            }else if((decoded?.resource_access[clientId]?.roles?.includes(Role.Doctor))){
                role = Role.Doctor;
            }
        }
        if(decoded?.sub){
            id = decoded?.sub;
        }
        userInstance.current = { role, id };

        return () => {
            userInstance.current = undefined;
        };
      }, [kcToken]);
    
      return userInstance;
};