import { Resource, Scope } from "./permissionsConstants";

export class PermissionUtil{

    static isAllowed (permissions, resource: Resource, scope: Scope): boolean  {
        if(permissions && permissions.length){
            const assessmentResource = permissions.find(permission => permission.rsname === resource);
            if(assessmentResource){
                const assessmentViewScope = assessmentResource.scopes.find(scp => scp === scope);
                if(assessmentViewScope){
                    return true;
                }
            }
        }
        return false;
    }
}