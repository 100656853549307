import { FC, ReactNode, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';
import { useKeycloak } from '@react-keycloak/web'
import { GetPermissionsQueryVariables, Permission, useGetPermissionsQuery } from 'src/generated/graphql';
import { useGraphqlRequestClient } from 'src/clients/graphqlRequestClient';
import { ConstructionOutlined } from '@mui/icons-material';
import { PermissionContext } from 'src/permissions/PermissionContext';
import Footer from 'src/shared/Footer';


interface SidebarLayoutProps {
  children?: ReactNode;
}

const MainWrapper = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 97%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: ${theme.sidebar.width};
        }
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
`
);

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const { keycloak } = useKeycloak(); 
  let [isPermissionLoaded, setIsPermissionLoaded] = useState<boolean>();
  let [permissions, setPermissions] = useState<Permission[]>();
  const { graphqlRequestClientInstance, clientInitialized } = useGraphqlRequestClient();
  
  let { isLoading, refetch: refetchPermission } = useGetPermissionsQuery<GetPermissionsQueryVariables>(graphqlRequestClientInstance.current, 
    null, {enabled: false});

  useEffect(() => {
      if(keycloak?.authenticated && clientInitialized){
        refetchPermission().then((response) => {
          const permissions = response?.data?.getPermissions;
          if(permissions){
            console.log("Permissions - resources and scopes", response?.data?.getPermissions);
            setPermissions(permissions);
          }
          

          setIsPermissionLoaded(true);
          
        }).catch((error) => {
          console.log("error: ", error);
        });
      }
  }, [keycloak?.authenticated, clientInitialized]);

  if (keycloak?.authenticated) {
    return (
      <>
        <PermissionContext.Provider value={permissions}>
          <Sidebar />
          <MainWrapper >
            <Header />
            <MainContent className='main-content'>
              <Outlet />
            </MainContent>
          </MainWrapper>
          <Footer />
        </PermissionContext.Provider>
      </>
    );
  }else{
    return (

      <Navigate
            to={{
              pathname: '/overview'
            }}
            replace
          />

    );
  }

};

export default SidebarLayout;
