
import { Box, Typography} from '@mui/material';
import Dropzone from 'react-dropzone'

import { ApiURL, MethodType, RequestConfig } from 'src/hooks/api-params';
import { useApiMutation } from 'src/hooks/useApiMutation';
import { useToasts } from 'react-toast-notifications';

import MediaCard from 'src/Users/cardupload';
import {useDropzone} from 'react-dropzone';

function ModifiedDropZone(props) {
    useDropzone();
    
    const { addToast } = useToasts();
    
    let apiUserUploadParam : RequestConfig<any> = {
        queryKey: 'upload-user',
        method: MethodType.post,
        //url: `${ApiURL.assessments.toString()}${!!urlParam && urlParam.id}`,    
        url: `${ApiURL.importusers.toString()}`,    
      };

    let { mutateAsync: postUserAsync } 
        = useApiMutation<any, any, Error>(apiUserUploadParam);
    
    function onupload(acceptedFiles) {
        
        var formData = new FormData();
        formData.append("files", acceptedFiles[0]);
        // formData.append('password', 'password');
        apiUserUploadParam.params = formData;
        
        postUserAsync(apiUserUploadParam).then(() => {
            addToast("Users uploaded successfully", {
                appearance: 'success',
                autoDismiss: true,
            }); 
        }).catch((ex) => {
            addToast("Unable to upload users", {
                appearance: 'error',
                autoDismiss: true,
            }); 
        });

    };
    return(
        <>
        <Dropzone onDrop={files => onupload(files)}>
            {({getRootProps, getInputProps}) => (
                <div className="container">
                <div
                    {...getRootProps({
                    className: 'dropzone',
                    onDrop: event => event.stopPropagation()
                    })}
                >
                    <input {...getInputProps()} />
                    <MediaCard />
                </div>
                </div>
            )}
        </Dropzone>
        </>
    );
}


const UploadUsers = () => {

    // const { addToast } = useToasts();
    
    // let apiUserUploadParam : RequestConfig<any> = {
    //     queryKey: 'upload-user',
    //     method: MethodType.post,
    //     //url: `${ApiURL.assessments.toString()}${!!urlParam && urlParam.id}`,    
    //     url: `${process.env.REACT_APP_BACKEND_API}${ApiURL.importusers}`,    
    //   };

      
    // let { mutateAsync: postUserAsync, isLoading } 
    //     = useApiMutation<any, any, Error>(apiUserUploadParam);
    
    // const onDrop =  (acceptedFiles) => {
        
    //     var formData = new FormData();
    //     formData.append("files", acceptedFiles[0]);
    //     formData.append('password', 'password');
    //     apiUserUploadParam.params = formData;
        
    //     postUserAsync(apiUserUploadParam).then(() => {
    //         addToast("Users uploaded successfully", {
    //             appearance: 'success',
    //             autoDismiss: true,
    //           }); 
    //     }).catch((ex) => {
    //         addToast("Unable to upload users", {
    //             appearance: 'error',
    //             autoDismiss: true,
    //           }); 
    //     });
    //   };

  return (
    <>
        <Box display="flex" mb={3} sx={{ pt: 3 }} justifyContent="space-between">
          <Box>
            <Typography variant="h2">Import Patients</Typography>
          </Box>
        </Box>

        <ModifiedDropZone />


        {/* <Box
            display="flex" justifyContent="center"
            alignItems="center" minHeight="20vh">
                <Card variant="outlined" sx={{pl: 10, pr:10, minWidth: 600}} >
                <CardMedia
                    component="img"
                    height="140"
                    image="/static/images/overview/ImportUsers_Image.svg"
                    alt="error"
                    //object-fit="cover"
                />
                <CardContent >
                    <Box display='flex' justifyContent='center' alignItems='center'>

                    {
                        isLoading && 
                        <Box sx={{ display: 'flex', overflowY: "hidden", opacity: 0.5, width: '100%' }} justifyContent='center' alignItems='center'>
                            <CircularProgress className='table-circular-loader' size={32} disableShrink thickness={3} />
                        </Box>
                    }
                    {
                        !isLoading &&
                        <Dropzone onDrop={onDrop}  >

                        {({getRootProps, getInputProps}) => (
                            <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <h3>Drag 'n' drop some files here, or click to select files</h3>
                            </div>
                            </section>
                        )}
                        </Dropzone>
                    }
                    </Box>

                </CardContent>
                </Card>
                <Box sx={{ p: 5}}>

                </Box>

        </Box>
        <Box display="flex" mb={3} justifyContent="flex-end">
        </Box> */}
        
    </>
  );
}

export default UploadUsers;
