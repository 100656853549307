
import { useEffect, useRef, useState } from 'react';
import { GraphQLClient } from 'graphql-request';
import { BaseUrl } from 'src/hooks/api-params';
import { useKeycloak } from '@react-keycloak/web';


export const useGraphqlRequestClient = () => {
    const graphqlRequestClientInstance = useRef<GraphQLClient>();
    const { keycloak, initialized } = useKeycloak();
    
    let [clientInitialized, setClientInitialized] = useState(false);
    let [kcToken, setKcToken] = useState(keycloak?.token ?? '');


    useEffect(() => {
    
        if (keycloak && initialized) {
          keycloak.onAuthRefreshSuccess = () => {
            setKcToken(keycloak?.token ?? '');
          } 
          keycloak.onAuthSuccess = () => {
            // console.log("onAuthSuccess fired");
          } 
        }
        
        const requestHeaders = {
          authorization: initialized ? `Bearer ${kcToken}` : undefined,
      };
      
        
      graphqlRequestClientInstance.current = new GraphQLClient(BaseUrl, {
        headers: requestHeaders,
    });
        setClientInitialized(true);
        
        return () => {
          setClientInitialized(false);
          graphqlRequestClientInstance.current = undefined;
        };
      }, [initialized, clientInitialized, kcToken]);

      return { graphqlRequestClientInstance, clientInitialized} ;
};