
import { Box, FormControl, MenuItem, TextField, Typography } from '@mui/material';
import { AssessmentFeatureConfig, AssessmentFeatureValue, FeatureInputType } from './patient-assessment.model';
import  { FeatureConfigs }  from './feature-configuration'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { CanGetData } from './can-get-data';

const AssessmentFeatureInput = forwardRef<CanGetData, { featureValue: AssessmentFeatureValue, config: AssessmentFeatureConfig, onInputChange }> 
    ((props: { featureValue: AssessmentFeatureValue, config: AssessmentFeatureConfig, onInputChange }, ref)  => {

    const [config, setConfig] = useState<AssessmentFeatureConfig | undefined>();

    const [featureValue, setFeatureValue] = useState<AssessmentFeatureValue>();

    const [isDirty, setDirty] = useState<boolean>(props?.featureValue?.isDirty);

    const [isError, setIsError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>('');
    const handleSelectChange = (event) => {
        const selectedFeature : AssessmentFeatureValue = {
            ...featureValue
        };
        
        if(selectedFeature.value !== Number(event?.target.value)){
            selectedFeature.value = Number(event?.target.value);
            setDirty(true);
        }
        setIsError(false);
        setErrorText('');
        setFeatureValue(selectedFeature);

        
    };

    const handleNumberTextChange = (event) => {
        const selectedFeature : AssessmentFeatureValue = {
            ...featureValue
        };
        if(event?.target?.value === '' || event?.target?.value === undefined || event?.target?.value === null){
            selectedFeature.value = undefined;
            setDirty(true);
        } else if(selectedFeature.value !== Number(event?.target?.value)){
            selectedFeature.value = Number(event?.target?.value);
            setDirty(true);
        }
        setIsError(false);
        setErrorText('');
        setFeatureValue(selectedFeature);
        props.onInputChange(selectedFeature, config)
    }
    useImperativeHandle(
        ref,
        () => ({
            getData: () => {
                return {number: props.config.featureId, value: featureValue?.value, isDirty: isDirty};
            },
            setData: (value) => {
                let _featureValue: AssessmentFeatureValue = (featureValue) ? featureValue : props.featureValue;
                _featureValue.value = value;
                setFeatureValue({ ... _featureValue});
            },
            validate: (): boolean => {
                if(props.config.mandatory){
                    if(featureValue?.value === undefined || featureValue?.value === null 
                        || featureValue?.value === -1){
                        setIsError(true);
                        setErrorText('This field is mandatory');
                        return false;
                    }
                }
                if(props.config.inputProps.min != undefined && props.config.inputProps.min != null &&  featureValue?.value < props.config.inputProps.min){
                    if(props.config.inputProps.max){
                        setIsError(true);
                        setErrorText(`Value must be between ${props.config.inputProps.min} and ${props.config.inputProps.max}`);
                        return false;
                    }
                    setIsError(true);
                    setErrorText(`Value must be greater than ${props.config.inputProps.min - 1}`);
                    return false;
                }
                if(props.config.inputProps.max != undefined && props.config.inputProps.max != null &&  featureValue?.value > props.config.inputProps.max){
                    if(props.config.inputProps.min){
                        setIsError(true);
                        setErrorText(`Value must be between ${props.config.inputProps.min} and ${props.config.inputProps.max}`);
                        return false;
                    }
                    setIsError(true);
                    setErrorText(`Value must be less than ${props.config.inputProps.max + 1}`);
                    return false;
                }
                setIsError(false);
                setErrorText('');
                return true;
            }
        }),
    )
    
    useEffect(() => {
        if(props.config?.featureId){
            const config:AssessmentFeatureConfig  = FeatureConfigs.find(f => f.featureId === props.config.featureId);
            if(config){
                setConfig(config);
            }
            if(props?.featureValue && props?.featureValue?.value !== undefined &&
                props?.featureValue?.value !== null && props?.featureValue?.value !== -1){
                setFeatureValue(props.featureValue);
            }
        }
    }, [config, props.featureValue]);
    
  return (
    <>
        { config &&
            
                <FormControl sx={{width: '100%'}}>
                    {config.type === FeatureInputType.MultipleChoice &&

                    <TextField
                        select
                        variant="standard"
                        value={featureValue?.value ?? ""}
                        onChange={handleSelectChange}
                        disabled={ config.disable }
                        inputProps={config.inputProps} 
                        InputProps={ {style:{fontSize: '0.9em'}}}
                        error={isError}
                        helperText={errorText}
                        >
                        {config?.options.map(({ value, label }) => 
                            <MenuItem key={value} value={value} >{label}</MenuItem>)
                        }
                        <MenuItem value="-1">
                            <em>None</em>
                        </MenuItem>
                    </TextField>
                    }
                    {config.type === FeatureInputType.Number && config.disable === false &&
                    <TextField
                        disabled={ config.disable }
                        type="number"
                        inputProps={ {style:{fontSize: '0.9em'}, ...config.inputProps} }
                        InputProps={ config.InputProps }
                        value={featureValue?.value ?? ""}
                        variant="standard"
                        onChange={handleNumberTextChange}
                        error={isError}
                        helperText={errorText}
                    />
                    }
                    {config.type === FeatureInputType.Number && config.disable === true &&                 
                        <Typography sx={{pl: '0.7em' , fontSize: '0.9em'}} >{featureValue?.value}</Typography>
                    }
                </FormControl>

        }
        
    </>
    
  );
});

export default AssessmentFeatureInput;
