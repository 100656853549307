import App from './App';


import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import 'nprogress/nprogress.css';
import { SidebarProvider } from './layouts/SidebarLayout/SidebarContext';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak'
import ReactDOM from 'react-dom/client';


const eventLogger = (event: unknown, error: unknown) => {
  if (`${event}` === "OnAuthRefreshError") {
      keycloak.logout();
  }
  }

const tokenLogger = (tokens: unknown) => {
  console.log('onKeycloakTokens', tokens)
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  
    <HelmetProvider>
      <SidebarProvider>
        <BrowserRouter>
          <ReactKeycloakProvider
            authClient={keycloak}
            onEvent={eventLogger}
            onTokens={tokenLogger}
          >
            <App />
          </ReactKeycloakProvider>
        </BrowserRouter>
      </SidebarProvider>
    </HelmetProvider>
);

serviceWorker.unregister();
