import { useRoutes, BrowserRouter, Route, Routes } from 'react-router-dom';
import routes from './router';


import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ToastProvider } from 'react-toast-notifications';
import { useEffect } from 'react';
import { getMainDefinition } from '@apollo/client/utilities';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { split, HttpLink, ApolloClient, InMemoryCache, useSubscription, gql, ApolloProvider } from '@apollo/client';
import { BaseWSUrl } from './hooks/api-params';


const App = () => {

  const { keycloak, initialized } = useKeycloak();
  
  const content = useRoutes(routes);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false
      }
    }
  })


  
  useEffect(() => {


    console.log('Environment Variables:');
    console.log('---------------------:');
    console.log('REACT_APP_IDENTITY_URL:', (window as any)._env_.REACT_APP_IDENTITY_URL);
    console.log('REACT_APP_IDENTITY_REALM_NAME:', (window as any)._env_.REACT_APP_IDENTITY_REALM_NAME);
    console.log('REACT_APP_IDENTITY_CLIENT_ID:', (window as any)._env_.REACT_APP_IDENTITY_CLIENT_ID);
    console.log('REACT_APP_BACKEND_API:', (window as any)._env_.REACT_APP_BACKEND_API);
    console.log('REACT_APP_WS_API:', (window as any)._env_.REACT_APP_WS_API);
    console.log('REACT_APP_DISPLAY_INFERENCE_DETAILS:', (window as any)._env_.REACT_APP_DISPLAY_INFERENCE_DETAILS);
    console.log('REACT_APP_DISPLAY_INFERENCE_SCORE:', (window as any)._env_.REACT_APP_DISPLAY_INFERENCE_SCORE);
    console.log('----------------------');
} , []);
  if (!initialized) {
    console.log("Loading Identity Provider...");
    return <div></div>
  }


  
  const kcToken = keycloak?.token ?? '';
  

  const wsLink = new GraphQLWsLink(createClient({
    url: BaseWSUrl,
    connectionParams: {
      authorization: `Bearer ${kcToken}`
    }
  }));
  
 

  const client = new ApolloClient({
    link: wsLink,
    cache: new InMemoryCache()
  });


  return (
    <ThemeProvider>
      <CssBaseline />
        <ToastProvider autoDismissTimeout={3000} placement='bottom-right'>
            <QueryClientProvider client={queryClient}>
              <ApolloProvider client={client}>
                {content}
              </ApolloProvider>
            </QueryClientProvider>
        </ToastProvider>
    </ThemeProvider>
  );
}
export default App;
