import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'


const keycloak = Keycloak({
  url: (window as any)._env_.REACT_APP_IDENTITY_URL, //'http://localhost:8081/auth'
  realm: (window as any)._env_.REACT_APP_IDENTITY_REALM_NAME, //'antenatal-realm',
  clientId: (window as any)._env_.REACT_APP_IDENTITY_CLIENT_ID //'antenatal-web',
});

export default keycloak;
