

import './assessments.css'
import { AssessmentHeader as AssessmentHeader, AssessmentFeature, AssessmentHeaderMode, AssessmentStatus, AssessmentPostRequest, FeatureDisplayMode, VisitType, ClinicalOutcome, AssessmentMode, FeatureInputType, AssessmentFeatureValue, AssessmentFeatureConfig } from './patient-assessment.model';
import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, 
    Typography, Stack, Switch, Button, Chip, Tooltip, lighten, TooltipProps, tooltipClasses, TextField } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import AssessmentFeatureInput from './assessment-feature-input';
import { CanGetData } from './can-get-data';
import { Close, WarningRounded } from '@mui/icons-material';
import {  Edit } from '@mui/icons-material';
import React from 'react';
import { calculateAge, getAssessmentsView, mergeLastAssessmentFeatureValuesWithPrevious, removeNewAssessment, toggleHistoryRecords } from './assessment-table-helper';
import { AssessmentTableRefModel } from './assessment-table-ref-model';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { NewAssessment } from './patient-assessment.constants';
import { Assessment, AssessmentInput, InferenceResultType, Patient } from 'src/generated/graphql';
import { Role } from 'src/enums/user-enums';
import { styled } from '@mui/material/styles';
import Hidden from '@mui/material/Hidden';
import { isValid } from 'date-fns';


const InferenceToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 520,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const AssessmentsTable = forwardRef<AssessmentTableRefModel, { patient: Patient, assessments: Assessment[], 
  onModeChange, setAssessmentIdForUpdate, tableContainerRef}>
    ((props: {  patient: Patient, assessments: Assessment[], onModeChange, setAssessmentIdForUpdate, tableContainerRef}, ref) => {

    const [hideHistoryRecords, setHideHistoryRecords] = useState<boolean>(false);
    const clinicalInfoAtAdmRef = useRef<CanGetData[]>([]);
    const clinicalInfoAtEachVisit = useRef<CanGetData[]>([]);
    const patientInfoAtAdmRef = useRef<CanGetData[]>([]);
    const clinicalInfoAtDelRef = useRef<CanGetData[]>([]);
    const [highlightPatientInput, setHighlightPatientInput] = React.useState(true);
    
    let [assessmentsHeader, setAssessmentsHeader] = useState<AssessmentHeader[] | undefined>();
    let [assessmentFeatures, setAssessmentFeatures] = useState<AssessmentFeature[] | undefined>();

    const tableRef = useRef(null);

    const onToggleHistoryRecords = () => {
        setHideHistoryRecords(!hideHistoryRecords);
        [assessmentsHeader, assessmentFeatures] = toggleHistoryRecords(!hideHistoryRecords, assessmentsHeader, assessmentFeatures);
        setAssessmentsHeader([...assessmentsHeader]);
        setAssessmentFeatures([... assessmentFeatures]);
        if(hideHistoryRecords){
            scrollToRight();
        }
    }


    const onToggleEditMode = (assessmentId: string) => {
        let assessmentMode = AssessmentMode.Readonly;

        const assessmentHeader = assessmentsHeader.find( assessment => assessment.assessmentId === assessmentId);
        if(assessmentHeader.mode === AssessmentHeaderMode.ShowEdit){
            // change the edit logo to close
            assessmentsHeader.find( assessment => assessment.assessmentId === assessmentId).mode = AssessmentHeaderMode.ShowClose;
            mergeLastAssessmentFeatureValuesWithPrevious(assessmentFeatures);
            removeNewAssessment(assessmentsHeader, assessmentFeatures);
            // make the selected assessment column as editable
            assessmentFeatures.map( (feature) => { 
                const featureValue = feature.featureValues.find(f => f.assessmentId === assessmentId);
                if(featureValue){
                  featureValue.mode = FeatureDisplayMode.Edit;
                  // Calculate birthdate
                  if(feature.featureId === 1 && props.patient.birthdate && !featureValue.value){
                      if(isValid(props.patient.birthdate * 1000)){
                          const age = calculateAge(props.patient.birthdate)
                          featureValue.value = age; 
                          featureValue.isDirty = true; 
                      }
                  }
                  // Add visit number as 1
                  if(feature.featureId === 35 && !featureValue.value){
                      featureValue.value = 1;
                      featureValue.isDirty = true;
                  }
                }
            });
            props.onModeChange(AssessmentMode.Edit);
            props.setAssessmentIdForUpdate(assessmentId);
        }else if (assessmentHeader.mode === AssessmentHeaderMode.ShowClose){
            // change the close logo to edit
            assessmentHeader.mode = AssessmentHeaderMode.ShowEdit;
            [assessmentsHeader, assessmentFeatures, assessmentMode] = getAssessmentsView(props.assessments, props.patient, highlightPatientInput, hideHistoryRecords);
            props.onModeChange(assessmentMode);
            props.setAssessmentIdForUpdate(assessmentId);
        }
        scrollToRight();
        setAssessmentsHeader([...assessmentsHeader]);
        setAssessmentFeatures([... assessmentFeatures]);
    }


    const onHighlightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHighlightPatientInput(event.target.checked);
        assessmentFeatures.map(
            a => a.featureValues.map((feature) => {
                feature.highlight  = (feature.inputByUserType === Role.Patient && event.target.checked)
            })
        );
        setAssessmentFeatures([...assessmentFeatures]);
    };

    const onInputChange = (feature : AssessmentFeatureValue, config: AssessmentFeatureConfig) => {
      // If feature id is height or weight
      if(config.featureId === 16 || config.featureId === 17 ){
        const heightInputComp =  clinicalInfoAtAdmRef.current?.find( comp => comp.getData().number === 16);
        const weightInputComp =  clinicalInfoAtAdmRef.current?.find( comp => comp.getData().number === 17);

        if(heightInputComp && weightInputComp){
          let height = 0;
          let weight = 0;

          if(config.featureId === 16){ // if the height value is changed
            height = feature.value;
            // get weight
            if(weightInputComp.getData().value){
              weight = weightInputComp.getData().value;
            }
          }

          if(config.featureId === 17){ // if the height value is changed
            weight = feature.value;
            if(heightInputComp.getData().value){
              height = heightInputComp.getData().value;
            }
          }

          if(height && weight){
            const bmiInputComp =  clinicalInfoAtAdmRef.current?.find( comp => comp.getData().number === 18);
            const bmi = parseFloat(((weight /height / height) * 10000 ).toFixed(2));
            bmiInputComp.setData(bmi);
          }
        }

      }

    }

    useImperativeHandle(
        ref,
        () => ({
            getData: () => {
                const assessmentInput: AssessmentInput = {
                    patientId: props?.patient?.id,
                    features: []
                };

                clinicalInfoAtAdmRef.current?.map((obj) => {
                        const feature = obj.getData();
                        if((feature.value || feature.value === 0) && feature.isDirty){    
                            assessmentInput.features.push({featureNumber: feature.number, value: feature.value});
                        }                        
                    }
                );


                clinicalInfoAtEachVisit.current?.map((obj) => {
                  const feature = obj.getData();
                  if((feature.value || feature.value === 0) && feature.isDirty){    
                    assessmentInput.features.push({featureNumber: feature.number, value: feature.value});
                  }                        
                  }
                );

                patientInfoAtAdmRef.current?.map((obj) => {
                    const feature = obj.getData();
                    if((feature.value || feature.value === 0) && feature.isDirty){
                        assessmentInput.features.push({featureNumber: feature.number, value: feature.value});
                    }                    
                }
                );
                clinicalInfoAtDelRef.current?.map((obj) => {
                    const feature = obj.getData();
                    if((feature.value || feature.value === 0) && feature.isDirty){
                        assessmentInput.features.push({featureNumber: feature.number, value: feature.value});
                    }                    
                }
                );

                return assessmentInput;
            },
            showHistoryAssessments: () => {
              onToggleHistoryRecords();
            },
            validate: () => {
              let isClinicalInfoAtAdmValid: boolean = true, isClinicalInfoAtEachVisitValid: boolean = true, 
                isPatientInfoAtAdmValid: boolean = true, isClinicalInfoAtDelValid: boolean = true;

              clinicalInfoAtAdmRef.current?.map((obj) => {
                  let isValid = obj.validate();
                  if(isClinicalInfoAtAdmValid && !isValid){
                    isClinicalInfoAtAdmValid = false;
                  }
                  
                }
              );
              clinicalInfoAtEachVisit.current?.map((obj) => {
                  let isValid = obj.validate();
                  if(isClinicalInfoAtEachVisitValid && !isValid){
                    isClinicalInfoAtEachVisitValid = false;
                  }                      
                }
              );
              patientInfoAtAdmRef.current?.map((obj) => {
                  let isValid = obj.validate();
                  if(isPatientInfoAtAdmValid && !isValid){
                    isPatientInfoAtAdmValid = false;
                  }                          
                }
              );
              clinicalInfoAtDelRef.current?.map((obj) => {
                  let isValid = obj.validate(); 
                  if(isClinicalInfoAtDelValid && !isValid){
                    isClinicalInfoAtDelValid = false;
                  }                    
                }
              );
              if(!isClinicalInfoAtAdmValid || !isClinicalInfoAtEachVisitValid || 
                  !isPatientInfoAtAdmValid || !isClinicalInfoAtDelValid){
                    
                    return false;
              }
      
              return true;
            },
            scrollToErrorText: () => {
              setTimeout(() => {
                  document.querySelector(`.Mui-error`).scrollIntoView({behavior: "smooth", block: "center"});                  
              }, 500);
          }
        })
        
    )

    const scrollToRight = () => {
        setTimeout(() => {
            if(props.tableContainerRef?.current && tableRef?.current){
                props.tableContainerRef.current.scrollTo({top: 0, left: tableRef.current.offsetWidth, behavior: 'smooth' });
            }
        }, 500);

    }

  
    useEffect(() => {
        let [headers, assessmentFeatures, assessmentMode] = getAssessmentsView(props.assessments, props.patient, highlightPatientInput, hideHistoryRecords);
        if(headers){
            setAssessmentsHeader([...headers]);
        }
        if(assessmentFeatures){
            setAssessmentFeatures([... assessmentFeatures]);
        }
        props.onModeChange(assessmentMode);
        scrollToRight();

    }, [props.assessments]);




  return (
      <>
        {assessmentsHeader &&
          <>
            <Table ref={tableRef} sx={{m: '0 auto', width: '100%'}}>
              <TableHead>
                <TableRow>
                  <TableCell key='empty-cell' className="sticky-header-left">
                    <Typography variant='inherit' gutterBottom fontSize='0.9em'>
                      Highlight patient inputs
                      <Switch checked={highlightPatientInput}
                        onChange={onHighlightChange}
                      /> 
                    </Typography>
                  </TableCell>
                      
                    {
                      assessmentsHeader.map((data, index) => {
                        return (
                          !!!data.hide && 
                          <TableCell key={index}  className={(index === assessmentsHeader.length - 1)? 'sticky-header-right': 'sticky-header'}
                          sx={{pt: '5px', pb: '5px'}}>
                            <Stack direction='row' alignItems='center' justifyContent='space-between' >
                            {/* Inference Id for troubleshooting purpose */}
                            <input type="hidden" value={data.inferenceUniqueId ?? ''} />
                              <Stack direction='row'>
                                  <Box>
                                    <Typography variant='inherit' sx={{px:2}} noWrap fontSize={data.mainText === NewAssessment? '0.9em' : '0.8em'} >
                                      {data.mainText}
                                      {
                                        data.displayInfo &&
                                          <InferenceToolTip  title={
                                              <React.Fragment>
                                                <Typography variant='subtitle1'  sx={{ml: '1em', mt:'1em', mb:'1em', fontWeight: 'bold'}}>
                                                  Updated By: {data.updatedByFullName} 
                                                </Typography>
                                                
                                                {
                                                data.displayInferenceDetails === true &&
                                                <TableContainer >
                                                  <Table size="small">
                                                    <TableHead>
                                                      <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell><Typography>Results</Typography></TableCell>
                                                        {
                                                          data.displayInferenceScore === true &&
                                                          <TableCell><Typography>Score (%)</Typography></TableCell>
                                                        }
                                                        
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      {
                                                        data.firstVisitBabyInferenceResult != null &&
                                                        <TableRow>
                                                          <TableCell>Early stage neonatal</TableCell>
                                                          <TableCell>    
                                                            {
                                                              data.firstVisitBabyInferenceResult === InferenceResultType.High &&
                                                              <Typography sx={{pt: .5}} fontSize='0.9em'
                                                                variant="body1" fontWeight="bold" color="error" gutterBottom>
                                                                HIGH
                                                              </Typography>
                                                            } 
                                                            {
                                                              data.firstVisitBabyInferenceResult === InferenceResultType.Low &&
                                                              <Typography sx={{pt: .5}} fontSize='0.9em'
                                                                variant="body1" fontWeight="bold" color="success.dark" gutterBottom>
                                                                LOW
                                                              </Typography>
                                                            }      
                                                            {
                                                              data.firstVisitBabyInferenceResult === InferenceResultType.NotAvailable &&
                                                              <Typography sx={{pt: .5}} fontSize='0.9em'
                                                                variant="body1" fontWeight="bold" color="warning.dark" gutterBottom>
                                                                N/A
                                                              </Typography>
                                                            }                                      
                                                          </TableCell>
                                                          {
                                                            data.displayInferenceScore === true &&
                                                            <TableCell>
                                                              {
                                                                data.firstVisitBabyInferenceScorePercent  &&
                                                                <Typography sx={{pt: .5}} fontSize='0.9em'
                                                                  variant="body1" gutterBottom>
                                                                    {data.firstVisitBabyInferenceScorePercent}
                                                                </Typography>
                                                              }      
  
                                                            </TableCell>
                                                          }

                                                      </TableRow>
                                                      }
                                                      {
                                                      data.firstVisitMotherInferenceResult != null &&
                                                      <TableRow>
                                                        <TableCell>Early stage maternal</TableCell>
                                                        <TableCell>                                                
                                                          {
                                                            data.firstVisitMotherInferenceResult === InferenceResultType.High &&
                                                            <Typography sx={{pt: .5}} fontSize='0.9em'
                                                              variant="body1" fontWeight="bold" color="error" gutterBottom>
                                                              HIGH
                                                            </Typography>
                                                          } 
                                                          {
                                                            data.firstVisitMotherInferenceResult === InferenceResultType.Low &&
                                                            <Typography sx={{pt: .5}} fontSize='0.9em'
                                                              variant="body1" fontWeight="bold" color="success.dark" gutterBottom>
                                                              LOW
                                                            </Typography>
                                                          }      
                                                          {
                                                            data.firstVisitMotherInferenceResult === InferenceResultType.NotAvailable &&
                                                            <Typography sx={{pt: .5}} fontSize='0.9em'
                                                              variant="body1" fontWeight="bold" color="warning.dark" gutterBottom>
                                                              N/A
                                                            </Typography>
                                                          }
                                                        </TableCell>
                                                        {
                                                          data.displayInferenceScore === true &&
                                                          <TableCell>
                                                            {
                                                              data.firstVisitMotherInferenceScorePercent  &&
                                                              <Typography sx={{pt: .5}} fontSize='0.9em'
                                                                variant="body1" gutterBottom>
                                                                  {data.firstVisitMotherInferenceScorePercent}
                                                              </Typography>
                                                            }      
                                                          </TableCell> 
                                                        }
                                                     
                                                      </TableRow>                                                    
                                                      }
                                                      {
                                                        data.firstVisitBabyMotherInferenceResult != null &&
                                                        <TableRow>
                                                          <TableCell>Early stage neonatal or maternal</TableCell>
                                                          <TableCell>
                                                            {
                                                              data.firstVisitBabyMotherInferenceResult === InferenceResultType.High &&
                                                              <Typography sx={{pt: .5}} fontSize='0.9em'
                                                                variant="body1" fontWeight="bold" color="error" gutterBottom>
                                                                HIGH
                                                              </Typography>
                                                            } 
                                                            {
                                                              data.firstVisitBabyMotherInferenceResult === InferenceResultType.Low &&
                                                              <Typography sx={{pt: .5}} fontSize='0.9em'
                                                                variant="body1" fontWeight="bold" color="success.dark" gutterBottom>
                                                                LOW
                                                              </Typography>
                                                            }      
                                                            {
                                                              data.firstVisitBabyMotherInferenceResult === InferenceResultType.NotAvailable &&
                                                              <Typography sx={{pt: .5}} fontSize='0.9em'
                                                                variant="body1" fontWeight="bold" color="warning.dark" gutterBottom>
                                                                N/A
                                                              </Typography>
                                                            }
                                                          </TableCell>
                                                          {
                                                            data.displayInferenceScore === true &&
                                                            <TableCell>
                                                              {
                                                                data.firstVisitBabyMotherInferenceScorePercent  &&
                                                                <Typography sx={{pt: .5}} fontSize='0.9em'
                                                                  variant="body1" gutterBottom>
                                                                    {data.firstVisitBabyMotherInferenceScorePercent}
                                                                </Typography>
                                                              }      
                                                            </TableCell>
                                                          }
                                                          
                                                        </TableRow>                                                    
                                                      }
                                                      {
                                                        data.lateStageBabyInferenceResult != null &&
                                                        <TableRow>
                                                          <TableCell>Late stage neonatal</TableCell>
                                                          <TableCell>
                                                            {
                                                              data.lateStageBabyInferenceResult === InferenceResultType.High &&
                                                              <Typography sx={{pt: .5}} fontSize='0.9em'
                                                                variant="body1" fontWeight="bold" color="error" gutterBottom>
                                                                HIGH
                                                              </Typography>
                                                            } 
                                                            {
                                                              data.lateStageBabyInferenceResult === InferenceResultType.Low &&
                                                              <Typography sx={{pt: .5}} fontSize='0.9em'
                                                                variant="body1" fontWeight="bold" color="success.dark" gutterBottom>
                                                                LOW
                                                              </Typography>
                                                            }      
                                                            {
                                                              data.lateStageBabyInferenceResult === InferenceResultType.NotAvailable &&
                                                              <Typography sx={{pt: .5}} fontSize='0.9em'
                                                                variant="body1" fontWeight="bold" color="warning.dark" gutterBottom>
                                                                N/A
                                                              </Typography>
                                                            }
                                                          </TableCell>
                                                          {
                                                            data.displayInferenceScore === true &&
                                                            <TableCell>
                                                              {
                                                                data.lateStageBabyInferenceScorePercent  &&
                                                                <Typography sx={{pt: .5}} fontSize='0.9em'
                                                                  variant="body1" gutterBottom>
                                                                    {data.lateStageBabyInferenceScorePercent}
                                                                </Typography>
                                                              }      
                                                            </TableCell>
                                                          }
                                                          
                                                        </TableRow>                                                    
                                                      }                                                    
                                                    </TableBody>
                                                  </Table>
                                                </TableContainer>                                                
                                                }

                                              </React.Fragment>
                                            }>
                                            <IconButton   
                                              aria-label="edit"  edge='end' sx={{p:0.3}} >                                                                        
                                              <InfoTwoToneIcon  sx={{width:'0.8em', height: '0.8em'}} />                                                                    
                                            </IconButton>
                                          </InferenceToolTip>
                                      }
                                    </Typography>
                                    {
                                      data.subText &&
                                      <Typography variant='inherit' sx={{px:3}} fontSize='0.8em' noWrap>
                                        {data.subText}
                                        {
                                          data.mode === AssessmentHeaderMode.ShowEdit &&                                                                
                                          <IconButton onClick={() => onToggleEditMode(data.assessmentId)} 
                                            aria-label="edit" style={{color: '#aa7dff'}} edge='end' sx={{p:0.3}} >                                                                        
                                            <Edit sx={{width:'0.8em', height: '0.8em'}} />                                                                    
                                          </IconButton>
                                        }
                                        {
                                          data.mode === AssessmentHeaderMode.ShowClose &&
                                          <IconButton onClick={() => onToggleEditMode(data.assessmentId)} 
                                              aria-label="edit" style={{color: '#aa7dff'}} edge='end' sx={{p:0.3}} >                                                                        
                                              <Close sx={{width:'0.8em', height: '0.8em'}} />                                                                    
                                          </IconButton>
                                        }
                                      </Typography>
                                    }
                                    {
                                      data.inferenceResult === InferenceResultType.Low &&
                                      <Typography sx={{pl: 3}} fontSize='1.0em'
                                        variant="body1" fontWeight="bold" color="success.dark" gutterBottom>
                                        LOW
                                      </Typography>
                                    }
                                    {
                                      data.inferenceResult === InferenceResultType.High &&
                                      <Typography sx={{pl: 3}} fontSize='1.0em'
                                        variant="body1" fontWeight="bold" color="error" gutterBottom>
                                        HIGH
                                      </Typography>
                                    }
                                    {
                                      data.inferenceResult === InferenceResultType.NotAvailable &&
                                      <Typography sx={{pl: 3}} fontSize='1.0em'
                                        variant="body1" fontWeight="bold" color="warning.dark" gutterBottom>
                                        N/A
                                      </Typography>
                                    }
                                  </Box>
                              </Stack>
                            </Stack>
                          </TableCell>
                        );
                      })
                    }
                  </TableRow>
                </TableHead>
                <TableBody>


                  {/* Clinical Information At Admission */}
                  <TableRow>
                    <TableCell className="sticky-group-header-left"> 
                      <Box display="flex" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" gap={1}>
                          <Typography fontWeight="bold" color="text.primary" fontSize={'0.9em'} gutterBottom>
                            1. Clinical Information Collected at Admission
                          </Typography>
                        </Stack>
                      </Box>
                    </TableCell>
                    {
                      !!!hideHistoryRecords && assessmentsHeader.length-1 > 0 &&
                      <TableCell className='sticky-group-header' colSpan={assessmentsHeader.length-1}></TableCell>                        
                    }
                    <TableCell className='sticky-group-header-right'></TableCell>
                  </TableRow>
                  {
                    assessmentFeatures.filter(assessmentFeature => assessmentFeature.visitType === VisitType.ClinicalInformationAtAdmission).map((assessment: AssessmentFeature, index) => {
                    return ( 
                      <TableRow key={assessment.uniquerId} 
                        style={{display: 'table-row' }}> 
                        <>
                          <TableCell key={`${assessment.featureId}_displayName`} sx={{pt: '5px', pb: '5px'}}
                            className={`sticky-row-left ${(index % 2) ? 'even' : 'odd'} `}>  
                            <Typography variant='subtitle1' className={assessment.mandatory?'required': ''} fontSize={'0.9em'}
                              fontWeight="bold">{assessment.displayName}</Typography>
                          </TableCell>
                            {
                              assessment.featureValues.map((featureValue) => {
                                return(
                                  !!!featureValue.hide && 
                                  <React.Fragment key={featureValue.uniqueId + '_container'}>
                                    {
                                      featureValue.mode === FeatureDisplayMode.Readonly && 
                                      <TableCell key={featureValue.uniqueId} sx={{pt: '5px', pb: '5px'}}
                                        className={`row ${(index % 2) ? 'even' : 'odd'} ${(featureValue.highlight)? 'highlight' : ''}`}>
                                        {
                                          <>
                                            {
                                              featureValue.config.type === FeatureInputType.Number  &&
                                              <Typography sx={{ml: 3}} fontSize={'0.9em'}>{featureValue.value}</Typography>
                                            }
                                            {
                                              featureValue.config.type === FeatureInputType.MultipleChoice  &&
                                              (featureValue.config.options.find( opt => opt.value === featureValue.value) !== undefined) &&
                                              
                                                <Typography sx={{ml: 3}} fontSize={'0.9em'}>
                                                  {featureValue.config.options.find( opt => opt.value === featureValue.value)?.label}
                                                </Typography>

                                            } 
                                          </>
                                        }
                                      </TableCell>
                                    }
                                    {
                                      (featureValue.mode === FeatureDisplayMode.New || 
                                          featureValue.mode === FeatureDisplayMode.Edit) && 
                                          <>
                                            <TableCell key={featureValue.uniqueId + 'tableCell'} sx={{pt: '5px', pb: '5px'}}
                                              className={`sticky-row-right ${(index % 2) ? 'even' : 'odd'} ${(featureValue.highlight)? 'highlight' : ''}`}>
                                              <AssessmentFeatureInput  key={featureValue.uniqueId + 'featureInputComponent'}                     
                                              ref={(ref) => { if(ref) {
                                                  clinicalInfoAtAdmRef.current[index] = ref;
                                              }}}
                                              featureValue={featureValue}
                                              config={featureValue.config}
                                              onInputChange={onInputChange}
                                              ></AssessmentFeatureInput>

                                            </TableCell>
                    
                                      </>

                                    }
                                  </React.Fragment>
                                )
                                })
                            }
                        </>
                      </TableRow>
                     );
                  })}






                  {/* Clinical Information At Each Visit */}
                  <TableRow>
                    <TableCell className="sticky-group-header-left"> 
                      <Box display="flex" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" gap={1}>
                          <Typography fontWeight="bold" color="text.primary" fontSize={'0.9em'} gutterBottom>
                            2. Clinical Information Collected at Each Visit
                          </Typography>
                        </Stack>
                      </Box>
                    </TableCell>
                    {
                      !!!hideHistoryRecords && assessmentsHeader.length-1 > 0 &&
                      <TableCell className='sticky-group-header' colSpan={assessmentsHeader.length-1}></TableCell>                        
                    }
                    <TableCell className='sticky-group-header-right'></TableCell>
                  </TableRow>
                  {
                    assessmentFeatures.filter(assessment => assessment.visitType === VisitType.ClinicalInformationAtEachVisit).map((assessment: AssessmentFeature, index) => {
                    return ( 
                      <TableRow key={assessment.uniquerId} 
                        style={{display: 'table-row' }}> 
                        <>
                          <TableCell key={`${assessment.featureId}_displayName`} sx={{pt: '5px', pb: '5px'}}
                            className={`sticky-row-left ${(index % 2) ? 'even' : 'odd'} `}>  
                            <Typography variant='subtitle1' className={assessment.mandatory?'required': ''} fontSize={'0.9em'}
                              fontWeight="bold">{assessment.displayName}</Typography>
                          </TableCell>
                            {
                              assessment.featureValues.map((featureValue) => {
                                return(
                                  !!!featureValue.hide && 
                                  <React.Fragment key={featureValue.uniqueId + '_container'}>
                                    {
                                      featureValue.mode === FeatureDisplayMode.Readonly && 
                                      <TableCell key={featureValue.uniqueId} sx={{pt: '5px', pb: '5px'}}
                                        className={`row ${(index % 2) ? 'even' : 'odd'} ${(featureValue.highlight)? 'highlight' : ''}`}>
                                        {
                                          <>
                                            {
                                              featureValue.config.type === FeatureInputType.Number  &&
                                              <Typography sx={{ml: 3}} fontSize={'0.9em'}>{featureValue.value}</Typography>
                                            }
                                            {
                                              featureValue.config.type === FeatureInputType.MultipleChoice  &&
                                              (featureValue.config.options.find( opt => opt.value === featureValue.value) !== undefined) &&
                                              
                                                <Typography sx={{ml: 3}} fontSize={'0.9em'}>
                                                  {featureValue.config.options.find( opt => opt.value === featureValue.value)?.label}
                                                </Typography>

                                            } 
                                          </>
                                        }
                                      </TableCell>
                                    }
                                    {
                                      (featureValue.mode === FeatureDisplayMode.New || 
                                          featureValue.mode === FeatureDisplayMode.Edit) && 
                                          <>
                                            <TableCell key={featureValue.uniqueId + 'tableCell'} sx={{pt: '5px', pb: '5px'}}
                                              className={`sticky-row-right ${(index % 2) ? 'even' : 'odd'} ${(featureValue.highlight)? 'highlight' : ''}`}>
                                              <AssessmentFeatureInput  key={featureValue.uniqueId + 'featureInputComponent'}                     
                                              ref={(ref) => { if(ref) {
                                                  clinicalInfoAtEachVisit.current[index] = ref;
                                              }}}
                                              featureValue={featureValue}
                                              config={featureValue.config}
                                              onInputChange={onInputChange}
                                              ></AssessmentFeatureInput>

                                            </TableCell>
                    
                                      </>

                                    }
                                  </React.Fragment>
                                )
                                })
                            }
                        </>
                      </TableRow>
                     );
                  })}



                  {/* Patient Information At Admission */}
                  <TableRow>
                    <TableCell className="sticky-group-header-left"> 
                      <Box display="flex" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" gap={1}>
                          <Typography fontWeight="bold" color="text.primary" fontSize={'0.9em'} gutterBottom>
                            3. Patient Information Collected at Admission 
                          </Typography>
                        </Stack>
                      </Box>
                    </TableCell>
                    {
                      !!!hideHistoryRecords && assessmentsHeader.length-1 > 0 &&
                      <TableCell className='sticky-group-header' colSpan={assessmentsHeader.length-1}></TableCell>                        
                    }
                    <TableCell className='sticky-group-header-right'></TableCell>
                  </TableRow>
                  {
                    assessmentFeatures.filter(assessment => assessment.visitType === VisitType.PatientInformationAtAdmission).map((assessment: AssessmentFeature, index) => {
                    return ( 
                      <TableRow key={assessment.uniquerId} 
                        style={{display: 'table-row' }}> 
                        <>
                          <TableCell key={`${assessment.featureId}_displayName`} sx={{pt: '5px', pb: '5px'}}
                            className={`sticky-row-left ${(index % 2) ? 'even' : 'odd'} `}>  
                            <Typography variant='subtitle1' className={assessment.mandatory?'required': ''} fontSize={'0.9em'}
                              fontWeight="bold">{assessment.displayName}</Typography>
                          </TableCell>
                            {
                              assessment.featureValues.map((featureValue) => {
                                return(
                                  !!!featureValue.hide && 
                                  <React.Fragment key={featureValue.uniqueId + '_container'}>
                                    {
                                      featureValue.mode === FeatureDisplayMode.Readonly && 
                                      <TableCell key={featureValue.uniqueId} sx={{pt: '5px', pb: '5px'}}
                                        className={`row ${(index % 2) ? 'even' : 'odd'} ${(featureValue.highlight)? 'highlight' : ''}`} >
                                        {
                                          <>
                                            {
                                              featureValue.config.type === FeatureInputType.Number  &&
                                              <Typography sx={{ml: 3}} fontSize={'0.9em'}>{featureValue.value}</Typography>
                                            }
                                            {
                                              featureValue.config.type === FeatureInputType.MultipleChoice  &&
                                              (featureValue.config.options.find( opt => opt.value === featureValue.value) !== undefined) &&

                                                <Typography sx={{ml: 3}} fontSize={'0.9em'}>
                                                  {featureValue.config.options.find( opt => opt.value === featureValue.value)?.label}
                                                </Typography>

                                            } 
                                          </>
                                        }
                                      </TableCell>
                                    }
                                    {
                                      (featureValue.mode === FeatureDisplayMode.New || 
                                          featureValue.mode === FeatureDisplayMode.Edit) && 
                                          <>
                                            <TableCell key={featureValue.uniqueId + 'tableCell'} sx={{pt: '5px', pb: '5px'}}
                                              className={`sticky-row-right ${(index % 2) ? 'even' : 'odd'} ${(featureValue.highlight)? 'highlight' : ''}`}>
                                              <AssessmentFeatureInput  key={featureValue.uniqueId + 'featureInputComponent'}                     
                                              ref={(ref) => { if(ref) {
                                                  patientInfoAtAdmRef.current[index] = ref;
                                              }}}
                                              featureValue={featureValue}
                                              config={featureValue.config}
                                              onInputChange={onInputChange}
                                              ></AssessmentFeatureInput>

                                            </TableCell>
                                          
                                      </>

                                    }
                                  </React.Fragment>
                                )
                                })
                            }
                        </>
                      </TableRow>
                     );
                  })}














                  {/* Clinical Information At Delivery */}
                  <TableRow>
                    <TableCell className="sticky-group-header-left"> 
                      <Box display="flex" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" gap={1}>
                          <Typography fontWeight="bold" color="text.primary" fontSize={'0.9em'} gutterBottom>
                            4. Clinical Information Collected at delivery
                          </Typography>
                        </Stack>
                      </Box>
                    </TableCell>
                    {
                      !!!hideHistoryRecords && assessmentsHeader.length-1 > 0 &&
                      <TableCell className='sticky-group-header' colSpan={assessmentsHeader.length-1}></TableCell>                        
                    }
                    <TableCell className='sticky-group-header-right'></TableCell>
                  </TableRow>
                  {
                    assessmentFeatures.filter(assessment => assessment.visitType === VisitType.ClinicalInformationAtDelivery).map((assessment: AssessmentFeature, index) => {
                    return ( 
                      <TableRow key={assessment.uniquerId} 
                        style={{display: 'table-row' }}> 
                        <>
                          <TableCell key={`${assessment.featureId}_displayName`} sx={{pt: '5px', pb: '5px'}}
                            className={`sticky-row-left ${(index % 2) ? 'even' : 'odd'} `}>  
                            <Typography variant='subtitle1' className={assessment.mandatory?'required': ''} fontSize={'0.9em'}
                              fontWeight="bold">{assessment.displayName}</Typography>
                          </TableCell>
                            {
                              assessment.featureValues.map((featureValue) => {
                                return(
                                  !!!featureValue.hide && 
                                  <React.Fragment key={featureValue.uniqueId + '_container'}>
                                    {
                                      featureValue.mode === FeatureDisplayMode.Readonly && 
                                      <TableCell key={featureValue.uniqueId} sx={{pt: '5px', pb: '5px'}}
                                        className={`row ${(index % 2) ? 'even' : 'odd'} ${(featureValue.highlight)? 'highlight' : ''}`} >
                                        {
                                          <>
                                            {
                                              featureValue.config.type === FeatureInputType.Number  &&
                                              <Typography sx={{ml: 3}} fontSize={'0.9em'}>{featureValue.value}</Typography>
                                            }
                                            {
                                              featureValue.config.type === FeatureInputType.MultipleChoice  &&
                                              (featureValue.config.options.find( opt => opt.value === featureValue.value) !== undefined) &&

                                                  <Typography sx={{ml: 3}} fontSize={'0.9em'}>
                                                    {featureValue.config.options.find( opt => opt.value === featureValue.value)?.label}
                                                  </Typography>

                                            } 
                                          </>
                                        }
                                      </TableCell>
                                    }
                                    {
                                      (featureValue.mode === FeatureDisplayMode.New || 
                                          featureValue.mode === FeatureDisplayMode.Edit) && 
                                          <>
                                            <TableCell key={featureValue.uniqueId + 'tableCell'} sx={{pt: '5px', pb: '5px'}}
                                              className={`sticky-row-right ${(index % 2) ? 'even' : 'odd'} ${(featureValue.highlight)? 'highlight' : ''}`}>
                                              <AssessmentFeatureInput  key={featureValue.uniqueId + 'featureInputComponent'}                     
                                              ref={(ref) => { if(ref) {
                                                  clinicalInfoAtDelRef.current[index] = ref;
                                              }}}
                                              featureValue={featureValue}
                                              config={featureValue.config}
                                              onInputChange={onInputChange}
                                              ></AssessmentFeatureInput>

                                            </TableCell>
                                          
                                      </>

                                    }
                                  </React.Fragment>
                                )
                                })
                            }
                        </>
                      </TableRow>
                     );
                  })}
                </TableBody>
            </Table>

        </>
      }
      </>
  );
});

export default AssessmentsTable;
