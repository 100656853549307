import { isValid, parse } from "date-fns";
import { Role } from "src/enums/user-enums";
import { Assessment, Patient } from "src/generated/graphql";
import { FeatureConfigs } from "./feature-configuration";
import { NewAssessment } from "./patient-assessment.constants";
import { AssessmentHeaderMode, AssessmentFeature, AssessmentFeatureValue, AssessmentHeader, AssessmentResponse, AssessmentStatus, AssessmentMode, FeatureDisplayMode } from "./patient-assessment.model";
const {format, differenceInYears} = require('date-fns');

export const getAssessmentsView = (
        assessments: Assessment[], 
        patient: Patient,
        highlightPatientInput: boolean, hideHistoryRecords: boolean): [AssessmentHeader[], AssessmentFeature[], AssessmentMode] => {

    let assessmentsHeader: AssessmentHeader[];
    let assessmentFeatures: AssessmentFeature[];

    let assessmentMode = AssessmentMode.Readonly;

    if(assessments && assessments.length > 0){
        // Filter auto generated assessments

        // assessments = assessments.filter(a => a.autogenerated !== 1);

        // const assessmentFeatures: AssessmentFeature[] = [];
        assessmentsHeader = getAssessmentsHeader(assessments, hideHistoryRecords);
        assessmentFeatures = getAssessmentFeatures(assessments, highlightPatientInput, hideHistoryRecords);
        [assessmentsHeader, assessmentFeatures, assessmentMode] = formatAssessments(assessments, patient, assessmentsHeader, assessmentFeatures, hideHistoryRecords);
    } else if(assessments && assessments.length === 0){
        console.log('assessment does not exists');
        assessmentsHeader = [getNewAssessmentHeader()]
        assessmentFeatures = getEmptyAssessment(patient);
        assessmentMode = AssessmentMode.New;
    }

    return [assessmentsHeader, assessmentFeatures, assessmentMode];
}
const getAssessmentFeatures = (
    assessments: Assessment[],
    highlightPatientInput: boolean,
    hideHistoryRecords: boolean
    ): AssessmentFeature[]  => {
    const assessmentFeatures: AssessmentFeature[] = [];
    FeatureConfigs.forEach((config) => {
        const assessmentFeature: AssessmentFeature = {
            featureId: config.featureId,
            uniquerId: `${config.featureId}_feature`,
            displayName: config.displayName,
            visitType: config.visitType,
            featureValues: [],
            mandatory: config.mandatory
        };
        assessments.forEach((assessement) => {
            const assessmentFeatureValue: AssessmentFeatureValue = {
                assessmentId: assessement.id,
                
                config,
                mode: FeatureDisplayMode.Readonly,
                uniqueId: `${config.featureId}_${assessement.id}`,
                isDirty: false,
                status: assessement.isFinalVersion,

            };
            if(assessement?.features){
                let feature = assessement?.features.find(f => f.featureNumber === config.featureId);  
                if(feature){
                    assessmentFeatureValue.value = feature.value;
                    assessmentFeatureValue.inputByUserType = feature.updatedByUserRole as Role;                    
                    if(highlightPatientInput && assessmentFeatureValue.inputByUserType === Role.Patient){     
                        assessmentFeatureValue.highlight = true;
                    }else{
                        assessmentFeatureValue.highlight = false;
                    }
                }
                           
            }
            assessmentFeature.featureValues.push(assessmentFeatureValue);     
            
        });
        assessmentFeatures.push(assessmentFeature);
    });
    return assessmentFeatures;
}

const getAssessmentsHeader = (assessments: Assessment[], hideHistoryRecords:boolean): AssessmentHeader[] => {
    const assessmentsHeader: AssessmentHeader[] = [];
    assessments.forEach((assessment: Assessment, index) => {
        const assessmentHeader: AssessmentHeader = { assessmentId: assessment.id, assessmentTimestamp: assessment.updatedAt };
        if(assessment?.updatedAt){
            const date = new Date(assessment.updatedAt);
            assessmentHeader.mainText = format(date, 'dd/MM/yy');
        }else{
            assessmentHeader.mainText = '';
        }

        assessmentHeader.subText = `${index + 1} of ${assessments.length}`;
        // assessmentHeader.classificationScore = assessment.classificationscore;
        
        assessmentHeader.inferenceResult = assessment.inferenceResult;

        assessmentHeader.firstVisitBabyInferenceResult = assessment.firstVisitBabyInferenceResult;
        assessmentHeader.firstVisitBabyInferenceScorePercent = assessment.firstVisitBabyInferenceScorePercent;

        assessmentHeader.firstVisitMotherInferenceResult = assessment.firstVisitMotherInferenceResult;
        assessmentHeader.firstVisitMotherInferenceScorePercent = assessment.firstVisitMotherInferenceScorePercent;
        
        assessmentHeader.firstVisitBabyMotherInferenceResult = assessment.firstVisitBabyMotherInferenceResult;
        assessmentHeader.firstVisitBabyMotherInferenceScorePercent = assessment.firstVisitBabyMotherInferenceScorePercent;

        assessmentHeader.lateStageBabyInferenceResult = assessment.lateStageBabyInferenceResult;
        assessmentHeader.lateStageBabyInferenceScorePercent = assessment.lateStageBabyInferenceScorePercent;
        assessmentHeader.inferenceUniqueId = assessment.inferenceUniqueId;

        // By default display more info icon. Hide the info icon for new assessment column and draft assessment column.
        assessmentHeader.displayInfo = true;

        assessmentHeader.displayInferenceDetails = false; 
        const displayInferenceDetails = ((window as any)._env_.REACT_APP_DISPLAY_INFERENCE_DETAILS) === "true";
        if(displayInferenceDetails && assessment.isFinalVersion){
            assessmentHeader.displayInferenceDetails = true;
        }

        assessmentHeader.displayInferenceScore = false; 
        const displayInferenceScore = ((window as any)._env_.REACT_APP_DISPLAY_INFERENCE_SCORE) === "true";
        if(displayInferenceScore && assessment.isFinalVersion){
            assessmentHeader.displayInferenceScore = true;
        }

        if(assessment.updatedBy?.firstName && assessment.updatedBy?.lastName){
            assessmentHeader.updatedByFullName = `${assessment.updatedBy.firstName} ${assessment.updatedBy.lastName}`;
        }
        
        assessmentsHeader.push(assessmentHeader);
    });
    return assessmentsHeader;
}

const formatAssessments = (
        assessmentResponse: Assessment[], 
        patient: Patient,
        assessmentsHeader: AssessmentHeader[], 
        assessmentFeatures: AssessmentFeature[],
        hideHistoryRecords: boolean): [AssessmentHeader[], AssessmentFeature[], AssessmentMode] => {

    const lastAssessment = assessmentResponse[assessmentResponse.length - 1];

    let assessmentMode = AssessmentMode.Readonly;
    if(lastAssessment){
        //If last assessment is closed
        if(lastAssessment.isFinalVersion === true){
            //Set last assessment header to editable mode
            assessmentsHeader[assessmentsHeader.length - 1].mode = AssessmentHeaderMode.ShowEdit;
            // Add new assessment
            const newAssessmentHeader = getNewAssessmentHeader();
            assessmentsHeader.push(newAssessmentHeader);

            //Copy the last assessment values to New Assessments
            assessmentFeatures.forEach((assessmentFeature) => {
                let newFeatureValue: AssessmentFeatureValue = {...assessmentFeature.featureValues[assessmentFeature.featureValues.length - 1]};
                newFeatureValue.uniqueId = `0_${assessmentFeature.featureId}_new_value`;
                newFeatureValue.mode = FeatureDisplayMode.New;


                // Add visit number as 1
                if(assessmentFeature.featureId === 35){
                    if(newFeatureValue.value){
                        newFeatureValue.value ++                        
                    }else{
                        newFeatureValue.value = 1;
                    }
                    newFeatureValue.isDirty = true;
                }                
                assessmentFeature.featureValues.push(newFeatureValue)
            });
            
            
            assessmentMode = AssessmentMode.New;
        }
        //If last assessment is ongoing
        if(lastAssessment.isFinalVersion === false){
            assessmentsHeader[assessmentsHeader.length - 1].mode = AssessmentHeaderMode.ShowEdit;
            assessmentMode = AssessmentMode.Readonly;
        }

    }
    setHeaderHistoryDisplay(hideHistoryRecords, assessmentsHeader);
    setFeatureHistoryDisplay(hideHistoryRecords, assessmentFeatures);
    return [assessmentsHeader, assessmentFeatures, assessmentMode];
}

const setHeaderHistoryDisplay = (hideHistoryRecords: boolean, assessmentsHeader: AssessmentHeader[]) => {
    assessmentsHeader.forEach((assessmentHeader, index) => {
        assessmentHeader.hide = false;
        if(hideHistoryRecords && index < assessmentsHeader.length - 1){
            assessmentHeader.hide = true;            
        }
    });
}

const setFeatureHistoryDisplay = (hideHistoryRecords: boolean, assessments: AssessmentFeature[]) => {
    // set hideHistoryRecords true exept the last item
    if(hideHistoryRecords){
        assessments.forEach((assessment) => {
            assessment.featureValues.forEach((featureValue, index) => {
                if(index < assessment.featureValues.length - 1){
                    featureValue.hide = true;
                }
            });
        });
    }
}

export const calculateAge = (dob) => {
    const date = new Date(dob * 1000);
    const age = differenceInYears(new Date(), date);
    return age;
}

export const getNewAssessmentHeader = () : AssessmentHeader => {
    const newAssessmentHeader: AssessmentHeader = {
        mainText: NewAssessment,
        displayInfo: false
    };
    return newAssessmentHeader
}


export const removeNewAssessment = (assessmentsHeader, assessments): void => {
    // remove if there is a new assessment
    if(assessmentsHeader[assessmentsHeader.length - 1].mainText === NewAssessment){
        assessmentsHeader.pop();
    }

    assessments.forEach( (feature) => { 
        if(feature.featureValues[feature.featureValues.length - 1].mode === FeatureDisplayMode.New){
            feature.featureValues.pop();
        }
    });
}

export const getEmptyAssessment = (patient: Patient) : AssessmentFeature[] => {
    const emptyAssessmentFeatures: AssessmentFeature[] = [];
    FeatureConfigs.forEach((config) => {
        const featureValue: AssessmentFeatureValue = {
            config: config,
            highlight: false,
            mode: FeatureDisplayMode.New,
            uniqueId: config.featureId + '_new_assessment',
            status: false,
            isDirty: false
        };

        // Calculate birthdate
        if(config.featureId === 1 && patient.birthdate){
            if(isValid(patient.birthdate * 1000)){
                const age = calculateAge(patient.birthdate)
                featureValue.value = age; 
                featureValue.isDirty = true; 
            }
        }

        // Add visit number as 1
        if(config.featureId === 35){
            featureValue.value = 1;
            featureValue.isDirty = true;
        }
        
        let assessment: AssessmentFeature = {
            featureId: config.featureId,
            displayName: config.displayName,
            featureValues: [featureValue],
            uniquerId: `${config.featureId}_new_feature`,
            visitType: config.visitType,
            mandatory: config.mandatory
        };
        emptyAssessmentFeatures.push(assessment);
    });
    return emptyAssessmentFeatures;
}

export const toggleHistoryRecords = (displayValue: boolean, assessmentsHeader: AssessmentHeader[], assessmentsFeatures: AssessmentFeature[]):
     [AssessmentHeader[], AssessmentFeature[]] => {
    assessmentsHeader.forEach((header: AssessmentHeader, index) => {
        if(index < assessmentsHeader.length - 1){
            header.hide = displayValue;
        }
    });
    assessmentsFeatures.forEach((assessmentFeature) => {
        assessmentFeature.featureValues.forEach((featureValue, index) => {
            if(index < assessmentFeature.featureValues.length - 1){
                featureValue.hide = displayValue;
            }
        })
    });
    return [assessmentsHeader, assessmentsFeatures];

}

export const mergeLastAssessmentFeatureValuesWithPrevious = (assessmentFeatures: AssessmentFeature[]) => {
    //IF the latest assessment is open and the second last assessment is closed, merge with the second last assessment with latest assessment.
    assessmentFeatures.forEach(assessmentFeature => {
        if(assessmentFeature.featureValues.length > 1){
            let latestAssessmentFeature = assessmentFeature.featureValues[assessmentFeature.featureValues.length - 1];
            let secondLastAssessmentFeature = assessmentFeature.featureValues[assessmentFeature.featureValues.length - 2];
            // if(latestAssessmentFeature.status === AssessmentStatus.Ongoing 
            //     && (secondLastAssessmentFeature.status === AssessmentStatus.Closed || secondLastAssessmentFeature.status === AssessmentStatus.SavePredictClose)){
            //     if(!latestAssessmentFeature.value){
            //         latestAssessmentFeature.value = secondLastAssessmentFeature.value;
            //     }
            // }
            if(latestAssessmentFeature.status === false 
                && (secondLastAssessmentFeature.status === true)){
                if(!latestAssessmentFeature.value){
                    latestAssessmentFeature.value = secondLastAssessmentFeature.value;
                }
            }
        }
        
    })
                
}