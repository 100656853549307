import { Box, Container, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import  Version  from 'src/version.json'
const FooterWrapper = styled(Box)(
  ({ theme }) => `
        border-radius: 0;
        
`
);

const VersionBadge = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.info.lighter};
        color: ${theme.colors.secondary};
        padding: ${theme.spacing(0.4, 1)};
        border-radius: ${theme.general.borderRadiusSm};
        text-align: center;
        display: inline-block;
        line-height: 1;
        font-size: ${theme.typography.pxToRem(11)};
`
);

function Footer() {
  return (
    <FooterWrapper >
      <Box sx={{m: '0px', pr: '50px'}} >
        <Box
          py={3}
          display={{ xs: 'block', md: 'flex' }}
          alignItems="center"
          textAlign={{ xs: 'left', md: 'left' }}
          justifyContent="space-between"
          sx={{m: '0px', p: '0px'}}
        >
          <Box>
            <Typography variant="subtitle1">
            </Typography>
          </Box>
          {/* <Tooltip title={'Version: ' + Version.web} arrow placement="right">
            <VersionBadge>{Version.web}</VersionBadge>
          </Tooltip> */}
          <Box>
            <Typography variant="caption" sx={{ textTransform: 'none', fontSize: '0.9em'}}>
              Antenatal CDSS - Not for clinical use
            </Typography>
          </Box>
        </Box>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
