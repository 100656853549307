import { AxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
import { useMutation, UseMutationResult } from 'react-query';
import { MethodType, RequestConfig } from './api-params';
import { useAxios } from './useAxios';


export const useApiMutation = <TResponseData, TRequestData, TError> (params: RequestConfig<TRequestData>): UseMutationResult<TResponseData, TError> => {

    const axiosInstance = useAxios();
    
    const mutateAxiosResponse = async (): Promise<TResponseData> => {  
        if(params){
            getAxiosRequestConfig(params);
            if(axiosInstance.current){
                let axiosResponse;
                if(params.method === MethodType.post){
                    axiosResponse = await axiosInstance.current
                    .post(params.url, params.params);
                }else if(params.method === MethodType.put){
                    axiosResponse = await axiosInstance.current
                    .put(params.url, params.params);
                }

                //.post<TResponseData,AxiosResponse<TResponseData,AxiosRequestConfig>,TRequestData>(axiosRequestConfig);
                return axiosResponse.data;
            } 
        }
        return undefined;
    }
    
    let response: UseMutationResult<TResponseData, TError> = useMutation<TResponseData, TError>(params?.queryKey, mutateAxiosResponse,  );
    
    useEffect(() => {
        return () => {
            response = undefined;
        };
      }, [params]);

    return response ;
  };

const getAxiosRequestConfig = <TRequestData>(params: RequestConfig<TRequestData>): AxiosRequestConfig<TRequestData> => {
    const axiosRequestConfig: AxiosRequestConfig<TRequestData> = {
        url: params?.url?.toString(),
        method: params?.method?.toString(),
        ...params
    };
    return axiosRequestConfig;
}